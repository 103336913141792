import React from 'react';
import {API_URL,CMS_URL} from "../../common/config"
import thumbnailDummyImage from '../../assets/generatingthumbnail.png'
import { ServiceWorker } from '../../services/ServiceWorker';
import { IconContext } from "react-icons";
import { FcOk,FcCurrencyExchange,FcExpired,FcCancel,FcPackage,FcHighPriority,FcInfo } from "react-icons/fc";

class CampaignDropDownComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          item:{},
          index: 0,
          selectValue:'',
          selectValueCopy:'',
          hideButton:true,
          isDisabled:false,
          campaign:this.props.campaign
        };

       
    }

    async componentDidMount(){
       this.renderData();
    }

    renderData(){
      console.log('is disabled campaignOpened? =',this.props.campaignOpened)
      

        console.log("test ",this.props.campaign,this.props.status)
        if(this.props.from=='table') {
            console.log('from table setting status= ',this.props.status[0].status)
            this.setState({
             selectValue:this.props.status[0].status, 
            })
        }
 
       console.log("DropDown component called")
       this.setState({
         item: this.props.item,
         index: this.props.index, 
       })
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.campaign !== prevProps.campaign) {
          this.renderData();
        }
      }
   


      async handleChange(e,item,idx){
       
          console.log(e.target.value)
          console.log(item)
          await this.setState({
            selectValue:e.target.value,
            itemID:item.id,
            selectedIndex:idx,
            hideButton:false
          },console.log(this.state.itemID))

          await this.changeCampaignStatusLocal()
          
        
      }

      async changeCampaignStatusLocal(e){
        try{e.preventDefault()}catch(e){console.log(e)}
        let response = await ServiceWorker.changeCampaignStatus(this.state.itemID,this.state.selectValue);
        if(response.status){
          console.log(response.data)
          try{
            this.props.changeCampaignListOrder(this.state.selectedIndex,response.data)
          
          }catch(e){
            console.log(e)
            window.location.reload()
          }
          
        }
      }

    render() {
      var index = this.state.index;
      var item = this.props.item;
      if(this.props.from=='table') {
        item=this.props.status[0]
      }
        return (
          
       <a className='option-status' key={this.props.campaign}>
         
         {console.log("this.state.isDisabled =>",this.state.isDisabled)}
         
       <select 
        value={this.state.selectValue} 
        onChange={(e)=>this.handleChange(e,item,index)} 
        disabled={this.state.isDisabled}
       >
       <option value="active">Active</option>
        <option value="pendingApproval">Pending Approval</option>
        <option value="deleted">Delete</option>
        {/* <option value="draft">Draft</option>
        <option value="expired">Expired</option>
        <option value="outOfMoney">Out of money</option> */}
        <option value="onHold">Hold</option>
      </select>
       </a>

        )
    }

}

export default CampaignDropDownComponent;