import React, { Component } from 'react';
import "./FloatingTextManager-style.css";
import Griddle, { plugins, RowDefinition, ColumnDefinition } from 'griddle-react';
import ReactModal from 'react-modal';
import { Table } from 'rsuite';
import {ServiceWorker} from '../../services/ServiceWorker';
import FloatingTextForm from './FloatingTextForm';

const customStyles = {
    content : {
      top                   : '45%',
      left                  : '58%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      height                 : '350px',
      width                 :'65%'
    }
  };
export default class FloatingTextManagerComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            showModal:false,
            selectedData:{},
            editMode:'update'
        }
      this.closeModal = this.closeModal.bind(this);
      this.updateTable = this.updateTable.bind(this);
    }

    async componentDidMount() {
        this.renderData();
    }

    async renderData() {
        let data = await ServiceWorker.getDeviceFloatingTexts()
        if(data.data.length==0){
            console.log("No data found")
        }
        console.log("data=",data.data)
        this.setState({
            data: data.data,
        });
    }

    openModal(e){
        console.log("open modal ",e);
        let fullData = this.state.data;
        console.log("selectedData",fullData)
        let selectedData = fullData.filter(function (elem){
            
            return elem._id==e;
        })
        console.log("selectedData",selectedData)
        if(selectedData.length==0){
            alert("unable to select this banner");
            return 
        }
        this.setState({
            selectedData: selectedData[0],
            showModal:true,
            editMode:'update'
        })
    }
    async updateTable(e){
        let full = this.state.data;
        await full.map((elem)=>{
            if (elem._id == e._id){
                elem=e
            }
        })
    }
    closeModal(){
        this.setState({
            showModal:false
        })
    }
    addNew(e){
        e.preventDefault();
        let toSet = {
            active:false,
            animation:'',
            duration:1,
            floatingtext:'',
            qrcode:false,
            qrurl:'',
            specialEffectText:'',
            animateColor:'#f19c09',
            _id:null
        }
        this.setState({
            selectedData: toSet,
            showModal:true,
            editMode:'add'

        })
    }
    
    render() {
        const { loading, displayLength, page, } = this.state;
        const CustomHeading = ({ title }) => <span style={{ color: 'black' }}>{title}</span>;
        const CustomColumn = ({ value }) => <span style={{ textAlign: 'left' }}>{value}</span>;
        const qrStatus = ({ value }) => <span style={{ textAlign: 'left' }}>{value==true?'ON':'OFF'}</span>;


        const openText = ({ value }) => <a
            onClick={(e) => this.openModal(value)} >
            <button style={{ cursor: 'pointer',float:'inherit' }}
            >✏️</button></a>
       
        return (
            <div className="floating-text-table">
                <button style={{ cursor: 'pointer',float:'right' }}
                    onClick={(e) => this.addNew(e)}
                 >➕Add new banner
                </button>
                <Griddle
                        data={this.state.data}
                        plugins={[plugins.LocalPlugin]}
                    >
                        <RowDefinition >
                            <ColumnDefinition id="id" title="No." customHeadingComponent={CustomHeading} customComponent={CustomColumn} />                         
                            <ColumnDefinition id="qrcode" title="QR status" customHeadingComponent={CustomHeading} customComponent={qrStatus} />                         
                            <ColumnDefinition id="qrurl" title="QR URL" customHeadingComponent={CustomHeading} /> 
                            <ColumnDefinition id="floatingtext" title="Floating Text" customHeadingComponent={CustomHeading} /> 
                            <ColumnDefinition id="specialEffectText" title="Special EffectText" customHeadingComponent={CustomHeading}/>
                            <ColumnDefinition id="open" title="Edit" customHeadingComponent={CustomHeading} customComponent={openText} />

                        </RowDefinition>
                </Griddle>
                <ReactModal 
                    isOpen={this.state.showModal}
                    contentLabel="Banner Options"
                    style={customStyles}
                >
                    <FloatingTextForm 
                        selectedData ={this.state.selectedData}
                        closeModal={this.closeModal}
                        updateTable={(e) =>this.updateTable(e)}
                        editMode={this.state.editMode}
                    />
                </ReactModal>

                
            </div>
        )
    }
}
