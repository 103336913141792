import React from 'react';
import Griddle, { plugins, RowDefinition, ColumnDefinition } from 'griddle-react';
import { ServiceWorker } from "../../services/ServiceWorker";
import { Table } from 'rsuite';
import {CC_URL} from '../../common/config'
import moment from 'moment';
import ReactModal from 'react-modal';
import "./UserRefundTable-styles.css";

const customStyles = {
    content : {
      top                   : '45%',
      left                  : '58%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      maxHeight             :'518px',
      width                 :'75%',
      minHeight             :"518px",
    }
  };

class UserRefundTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            initiateFullRefund: true,
            campaignOpened:false,
            detailedPayout:[],
            tempDetailed:[],
            showModal:false,
            mobileUserID:'',
            vouchers:[],
            selectedChargeID:'',
            selectedChargeAmount:'',
            refundReason:'',
            selectedVoucherID:'',
            showLoader:false,
            showSuccess:false,
            showFailed:false
        }
      this.toggleRadio = this.toggleRadio.bind(this);
      this.refundReasonText = this.refundReasonText.bind(this);
      this.updatePartialCharge = this.updatePartialCharge.bind(this);
    }

    async componentDidMount() {
        this.renderData();
    }

    async renderData() {
        let mobileUserID = this.props.mobileUserID;
        
        let vouchers = await ServiceWorker.getRedeemedVoucherByUserID(mobileUserID);
        vouchers.map(elems=>{
            elems.refundURL = elems.refundURL.split("/")[3];
            if(elems.payoutStatus!='paid'){
                elems.payoutStatus='Not paid'
            }
        })
        console.log('5f96a7c6ba7cab0032a865d4 =',vouchers)

        this.setState({
            mobileUserID: mobileUserID,
            vouchers:vouchers.reverse()
        });
    }


    componentDidUpdate(prevProps,prepState) {
        // Typical usage (don't forget to compare props):
        if ((this.props.mobileUserID !== prevProps.mobileUserID)) {
            this.renderData();
        }
        if ((this.props.mobileUserEmail !== prevProps.mobileUserEmail)) {
            this.renderData();
        }
    }

    async toggleRadio(){
        this.setState({
            initiateFullRefund:!this.state.initiateFullRefund

        })
    }

    async closeModal(){
        await this.setState({
            showModal:false,
            selectedChargeID:'',
            selectedChargeAmount:'',
            selectedVoucherID:'',
            showLoader:false,
            showSuccess:false,
            showFailed:false
        })   
    }

    refundReasonText(e){
        console.log('e=',e.target.name,e.target.value)
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    updatePartialCharge(e){
        console.log('e=',e.target.name,e.target.value)
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    async refundAmount(e,id){
        e.preventDefault()
        console.log("id = ",id)
        let fullDetail = this.state.vouchers;
        let voucherIDs = fullDetail.filter(function (elem) {
            if(elem.id==id){
                return elem.id;
            }
        })
        await this.setState({ 
            selectedVoucherID:voucherIDs[0].id,
            selectedChargeID:voucherIDs[0].refundURL,
            selectedChargeAmount:Number(voucherIDs[0].cost),
            showModal:true
        })
        console.log("voucherIDs=",voucherIDs)
    }

    validateRefundForm(){
        if(this.state.initiateFullRefund==false && this.state.partialAmount<=0){
            return {'status':false,'message':'Partial amount cannot be 0.'}
        }
        if(this.state.refundReason.length<=10){
            return {'status':false,'message':'Please be more detailed in the refund reason.'}
        }
        return {'status':true,'message':''}

    }

    async initiateRefund(e){
        e.preventDefault();
        console.log("initiateRefund called.")
        let isFormValid = await this.validateRefundForm();

        if (isFormValid.status==false){
            // stop show error.
            this.setState({
                errorMsg:isFormValid.message
            })
        }
        //prepare payload. 
        this.setState({
            showLoader:true
        })
        var payload={}
        if(this.state.initiateFullRefund==true){
             payload={
                'charge':this.state.selectedChargeID,
                'amount':Number(this.state.selectedChargeAmount)*100,
            }
        }else{
            payload={
                'charge':this.state.selectedChargeID, 
                'amount':Number(this.state.partialAmount)*100,
            }
        }
        let refundResponse = await ServiceWorker.initiateRefund(payload,this.state.selectedVoucherID);
        console.log('refundResponse=',refundResponse)
        if(refundResponse.status==false) {
            this.setState({
                showLoader:false,
                showFailed:true
            })
        }else{
        this.setState({
            showLoader:false,
            showSuccess:true
        })
        }

    }





    render() {
        const { loading, displayLength, page, } = this.state;
        const CustomHeading = ({ title }) => <span style={{ color: 'black' }}>{title}</span>;
        const CustomColumn = ({ value }) => <span style={{ textAlign: 'left' }}>{value}</span>;

        const refund = ({ value }) => <a
        onClick={(e) => this.refundAmount(e,value)} >
        <button style={{ cursor: 'pointer' ,float:'inherit'}}>Refund</button></a>


        return (
            <div className='purchase-history-body' style={{ display: "flex", background: "white" }}>
                <div className='purchase-history-table' style={{ width: "100%" }}>
                    <h3>Redeemed vouchers by User: {this.state.mobileUserID} , ({this.props.mobileUserEmail})</h3>
                    <Griddle
                        data={this.state.vouchers}
                        plugins={[plugins.LocalPlugin]}
                    >
                        <RowDefinition >
                            <ColumnDefinition id="campaignID" title="campaignID" customHeadingComponent={CustomHeading} customComponent={CustomColumn} />                         
                            <ColumnDefinition id="payoutStatus" title="Merchant Payout Status" customHeadingComponent={CustomHeading} /> 
                            <ColumnDefinition id="publisherID" title="Merchant ID" customHeadingComponent={CustomHeading} /> 
                            <ColumnDefinition id="cost" title="Charged Amount $" customHeadingComponent={CustomHeading} /> 
                            {/* <ColumnDefinition id="redeemID" title="redeemID" customHeadingComponent={CustomHeading} /> */}
                            <ColumnDefinition id="refundURL" title="charge ID" customHeadingComponent={CustomHeading} />
                            {/* <ColumnDefinition id="userID" title="userID" customHeadingComponent={CustomHeading}/> */}
                            <ColumnDefinition id="id" title="Initiate Refund" customHeadingComponent={CustomHeading} customComponent={refund} />


                            {/* <ColumnDefinition id="id" title="Action" customHeadingComponent={CustomHeading} customComponent={this.state.campaignOpened===true?customActivate:customActivateDisabled} /> */}
                            
                        </RowDefinition>
                    </Griddle>
                    <ReactModal 
                    isOpen={this.state.showModal}
                    contentLabel="Detailed Payout View"
                    style={customStyles}
                    >
                   <div className="payout-table"> 
                   <button className="common-button" 
                   onClick={(e)=>this.closeModal(e)}
                   >Close</button>
                   
                   <form className="refund-form">
                       <h3>Initiate Refund</h3> 
                       <div className="refund-form-section">
                        <div>
                        <label>Stripe charge ID</label>
                       <input
                            type="text"
                            defaultValue={this.state.selectedChargeID}
                            disabled={true}
                        />
                        </div>
                        
                       <div> 
                       <input type="radio" id="partial" name="partial" value="partial"
                        checked={this.state.initiateFullRefund==false?true:false}

                       onChange={this.toggleRadio}
                       />
                        <label for="partial">Partial</label>
                       </div>
                       {this.state.initiateFullRefund==false?
                        <div> 
                            <label>Amount</label> <input 
                            type="number" id="partialAmount" name="partialAmount" min="1" max={String(this.state.selectedChargeAmount)}
                            onChange={this.updatePartialCharge}
                            />
                            <p>Max amount {this.state.selectedChargeAmount}</p>
                        </div>
                        

                        :null}
                        
                        <div> 
                        <input type="radio" id="full" name="full" value="full"
                        checked={this.state.initiateFullRefund==true?true:false}
                        onChange={this.toggleRadio}
                        />
                        <label for="full">Full</label>
                        </div>

                        <label >Refund Reason</label>
                        <div>

                        <textarea id="refundReason" name="refundReason" rows="4" cols="50"
                        onChange={this.refundReasonText}
                        >
                        </textarea>    
                        </div>
                        {this.state.showLoader==false?
                        <button className='common-button'
                        onClick={(e)=>this.initiateRefund(e)}
                        disabled={this.state.showSuccess==true?true:false}
                        >Submit</button>:
                        <div  style={{width:"54px",height:"54px",marginLeft:"6%",marginTop:"2%"}}className="loader-blank"></div> 
                        }
                       </div>
                    {this.state.showSuccess==true?<p>Refund successful !!</p>:null}
                    {this.state.showFailed==true?<p>Refund Failed !!</p>:null}
                    </form>
                   </div>
                </ReactModal>
                </div>
            </div>
        )
    }


}
export default UserRefundTable;