import React from 'react'
import { FaClosedCaptioning } from 'react-icons/fa';
import {primaryColor, whiteColor,commonButtonColor} from "../../common/config";
import { ServiceWorker } from "../../services/ServiceWorker";
import "./AccountHistory-style.css";
import 'rsuite-table/dist/css/rsuite-table.css';
import {CommonFunctions} from '../../common/CommonFunctions';

import { FaFileDownload } from "react-icons/fa";
import { IconContext } from "react-icons";
import csvDownload from 'json-to-csv-export'
import moment from 'moment';
import Griddle, { plugins, RowDefinition, ColumnDefinition} from 'griddle-react';

import { Table } from 'rsuite';
const { Column, HeaderCell, Cell, Pagination } = Table;
moment().format();

class PurchaseHistory extends React.Component{
    constructor(props)
    {   super(props);
        this.state={
            currentlySelected:'campaign',
            displayLength: 10,
            loading: false,
            page: 1,
            data:[],
            isReady:0
        }

        this.downloadData = this.downloadData.bind(this);
      
    }

    async componentDidMount()
    {
        console.log("component called.");
        //const keys_to_keep = ['cost', 'userID','campaignID','updatedAt'];
        let data = await ServiceWorker.voucherMethod('purchases');
        await this.setState({
            data:data,
            isReady:1
        })
        console.log("table data=",this.state.data)
    }

    async downloadData(){
        console.log("Download Called.");
        let today = moment(new Date()).format('MM-DD-YYYY')
        let localData = this.state.data;
        localData.forEach(obj => CommonFunctions.renameKey(obj, 'campaignID', 'campaignName'));
        localData= CommonFunctions.RemoveKeysFromArrayOfObjects(localData,['campaignName','updatedAt','cost']);
        
        csvDownload(localData,today+'_purchaseHistory.csv')
    }
    render(){
        const { loading, displayLength, page,data } = this.state;
        const CustomHeading = ({title}) => <span style={{ color: 'black' }}>{title}</span>;
        if(this.state.isReady==0)
        {
            return (
               
                  <div  style={{width:"54px",height:"54px",marginLeft:"6%",marginTop:"2%"}}className="loader-blank"></div> 
            )
        }

        return (
            <div className='purchase-history-body' style={{display:"flex"}}>
                <div className='purchase-history-table' style={{width:"100%"}}>                   
                  <Griddle
                    data={data}
                    plugins={[plugins.LocalPlugin]}
                  >
                    <RowDefinition>
                        <ColumnDefinition id="campaignID" title="Campaign Name" customHeadingComponent={CustomHeading}/>
                        <ColumnDefinition id="cost" title="Cost $" customHeadingComponent={CustomHeading}/>
                        <ColumnDefinition id="updatedAt" title="Updated At" customHeadingComponent={CustomHeading} />   
                    </RowDefinition>
                </Griddle>
                </div>
                {this.state.data.length>0?<div className='campaign-history-download' style={{height:"fit-content",width:"auto"}} onClick={this.downloadData}>
                <IconContext.Provider value={{ color: commonButtonColor,size:"2em", className: "upper-icons-three" }}>
                        <FaFileDownload />
                    </IconContext.Provider>
                </div>:""}
            </div>
        )

    }

}
export default PurchaseHistory;
