import React, { Component } from 'react'
import Switch from "react-switch";
import {ServiceWorker} from "../../services/ServiceWorker";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { IconContext } from "react-icons";
import { AiFillCloseSquare } from "react-icons/ai";
import "./FloatingTextManager-style.css";


export default class FloatingTextForm extends Component {
    constructor() {
        super();
        this.state = { 
            qrcode:false,
            qrurl:'',
            options : ['bounce','flash','jello','pulse','rotate',
                'rubberBand','shake','swing','tada','wobble'],
            animation:'fading',
            floatingtext:"",
            specialEffectText:"",
            active:false,
            duration:null,
            isLoading:false,
            _id:null,
            animateColor:'#f19c09'
        };
        this.handelInputChange = this.handelInputChange.bind(this);
        this._onSelect = this._onSelect.bind(this);
      }
    async componentDidMount(){
        let selectedData = this.props.selectedData;
        console.log("selectedData 1",selectedData);
        
            let {active,animation,duration,floatingtext,
                qrcode,qrurl,specialEffectText,_id,animateColor} = selectedData;
            console.log("animationValue ",animation,animateColor)
            await this.setState({
                active:active,
                animation:animation,
                duration:duration,
                floatingtext:floatingtext,
                qrcode:qrcode,
                qrurl:qrurl,
                specialEffectText:specialEffectText,
                _id:_id,
                animateColor:animateColor
            })
            
            
    }

    handleChange(name) {
       if(name =='active') {
           this.setState({
               'active':!this.state.active
           })
       }else{
        this.setState({
            'qrcode':!this.state.qrcode
        })
       }
    }

    _onSelect(e){
        console.log("selected ",e);
        this.setState({
            animation:e
        })
    }

    async save(e){
        e.preventDefault();
        this.setState({isLoading:true})
        console.log("save clicked", this.state)
        console.log("duration",this.state.duration)
        let {active,animation,duration,floatingtext,
            qrcode,qrurl,specialEffectText,_id,animateColor} = this.state;
            animation=animation.value;
        let data ={
            active:active,
            animation:animation,
            duration:duration*1000,
            floatingtext:floatingtext,
            qrcode:qrcode,
            qrurl:qrurl,
            specialEffectText:specialEffectText,
            _id:_id,
            animateColor:animateColor
        }
      
        let update= await ServiceWorker.postDevice(this.props.editMode,data);
       console.log("update ",update)
        if(update.status==true){
            window.location.reload();
        }else{
            alert("unable to update device banner text")
        }
        this.setState({isLoading:false})
    }
    closeModal(e){
        e.preventDefault();
        console.log("close clicked")
        this.setState({
            qrcode:false,
            qrurl:'',
            options : ['bounce','flash','jello','pulse','rotate',
            'rubberBand','shake','swing','tada','wobble'],
            animation:'fading',
            floatingtext:"",
            specialEffectText:"",
            active:false,
            duration:null,
            _id:null,
            animateColor:'#f19c09'
        })
        this.props.closeModal()
    }
    handelInputChange(e) {
        console.log("handelInputChange",e.target.name,e.target.value);
        this.setState({
            [e.target.name]:e.target.value
        })

    }
    render() {
        return (
            <div className="floating-text-form">
                <div className="block-header">
                <h3>Floating Banner Option</h3> 
                <span style={{ cursor: 'pointer' }} onClick={(e)=>this.closeModal(e)}>
                <IconContext.Provider value={{ color:'red',size:"2em", className: "upper-icons-three" }}>
                        <AiFillCloseSquare />
                    </IconContext.Provider>
                </span>
                </div>
                <div className="block">
                    <label>
                        <span>Activate Banner</span>
                        <Switch onChange={(e) =>this.handleChange('active')} checked={this.state.active} />
                    </label>

                    <label>
                        <span>Enable QR</span>
                        <Switch onChange={(e) =>this.handleChange('qrcode')} checked={this.state.qrcode} />
                    </label>

                    <label>
                        <span>QR URL</span>
                        <input type="text" id="qrurl" name="qrurl" onChange={(e)=>this.handelInputChange(e)}
                            defaultValue={this.state.qrurl}
                            
                        ></input>
                    </label>
                </div>

                <div className="block">
                <label>
                    <span>FloatingText</span>
                    <textarea type="text" id="floatingtext" name="floatingtext" 
                    defaultValue={this.state.floatingtext}
                    onChange={(e)=>this.handelInputChange(e)}></textarea>
                </label>

                <label>
                    <span>Special Effect text</span>
                    <input type="text" id="specialEffectText" name="specialEffectText" 
                    defaultValue={this.state.specialEffectText}
                    onChange={(e)=>this.handelInputChange(e)}
                    required={true}
                    ></input>
                    
                </label>

                <label>
                    <span>Duration</span>
                    <input type="number" id="duration"
                    defaultValue={this.state.duration}
                     name="duration" onChange={(e)=>this.handelInputChange(e)}></input>
                </label>
                </div>
                <div className="block">
                

                <label>
                    <span>Animation</span>
                    <Dropdown options={this.state.options} onChange={this._onSelect} 
                    value={this.state.animation} placeholder="Select an option" />
                </label>

                <label>
                    <span>Animation color</span>
                     <input type="color" id="animateColor" name="animateColor" defaultValue={this.state.animateColor}
                     onChange={(e)=>this.handelInputChange(e)}
                     ></input>
                </label>
                {this.state.isLoading?<div className="loader-blank"></div>:
                <button className="common-button"
                    onClick={(e) =>this.save(e)}
                >save</button>
                }
                </div>
            </div>
        )
    }
}
