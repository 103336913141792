import React from 'react'
import { FaClosedCaptioning } from 'react-icons/fa';
import {primaryColor, whiteColor,commonButtonColor} from "../../common/config";
import { ServiceWorker } from "../../services/ServiceWorker";
import "./AccountHistory-style.css";

import 'rsuite-table/dist/css/rsuite-table.css';
import { Table } from 'rsuite';
import PurchaseHistory from './PurchaseHistory';
//import CampaignLaunchHistory from './CampaignLaunchHistory';
import LedgerHistory from './LedgerHistory';
import PayoutHistory from './PayoutHistory';
//import SubscriptionHistory from './SubscriptionHistory';



const { Column, HeaderCell, Cell, Pagination } = Table;

class AccountHistoryComponent extends React.Component{
    constructor(props)
    {   super(props);
        this.state={
            currentlySelected:'campaign',
            displayLength: 10,
            loading: false,
            page: 1,
            data:[]
        }

        this.optionSelector = this.optionSelector.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeLength = this.handleChangeLength.bind(this);
    }

    async componentDidMount()
    {
        console.log("component called.");
        //const keys_to_keep = ['cost', 'userID','campaignID','updatedAt'];
        let data = await ServiceWorker.voucherMethod('purchases');
        await this.setState({
            data:data,
        })
        console.log("table data=",this.state.data)
    }

    optionSelector(e,name)
    { e.preventDefault();
        this.setState({
            currentlySelected:name,
        })
        console.log(name)
    }

    handleChangePage(dataKey) {
        this.setState({
          page: dataKey
        });
      }
    
    handleChangeLength(dataKey) {
        this.setState({
          page: 1,
          displayLength: dataKey
        });
      }

    render(){
        
        let optionToShow;
        switch(this.state.currentlySelected)
        {
            case 'purchase':
                optionToShow = <PurchaseHistory />
                break;
            
            case 'ledger':
                optionToShow = <LedgerHistory/>
                break;
            
            case 'payout':
                optionToShow = <PayoutHistory/>
                break;
            
            case 'subscription':
               // optionToShow = <SubscriptionHistory/>
                break;
            default:
                optionToShow = <PurchaseHistory />
                break;
        }


        return (
            <div className="account-history-main-body">
                <ul style={{position:"relative",right:"0px",zIndex:"0",marginBlockEnd:"0px",bottom:"0px",width:"12%",borderRight:"0.5px solid"}} className="history-navigation-options">
                    {/* <li name='campaign' onClick={(e)=> this.optionSelector(e,'campaign')}>
                        <a style={this.state.currentlySelected=='campaign'?{color:"#3d4160",borderColor:commonButtonColor}:{}} >Campaign launch</a>
                    </li> */}

                    <li id='ledger' onClick={(e)=> this.optionSelector(e,'ledger')} >
                        <a style={this.state.currentlySelected=='ledger'?{color:"#3d4160",borderColor:commonButtonColor,cursor:"pointer"}:{}} >Ledger</a>
                    </li>

                    <li id='payout' onClick={(e)=> this.optionSelector(e,'payout')}>
                        <a style={this.state.currentlySelected=='payout'?{color:"#3d4160",borderColor:commonButtonColor,cursor:"pointer"}:{}} >Payouts</a>
                    </li>

                    <li id='purchase' onClick={(e)=> this.optionSelector(e,'purchase')}>
                        <a style={this.state.currentlySelected=='purchase'?{color:"#3d4160",borderColor:commonButtonColor,cursor:"pointer"}:{}} >Purchase</a>
                    </li>

                    {/* <li id='subscription' onClick={(e)=> this.optionSelector(e,'subscription')}>
                        <a style={this.state.currentlySelected=='subscription'?{color:"#3d4160",borderColor:commonButtonColor}:{}} >Subscription</a>
                    </li> */}
                </ul>
                <div className="history-table">

                    {optionToShow}
               
                   <div className='table-pagination-block'>
                   
                   </div>

                   
                </div>

            </div>
        )
    }
}

export default AccountHistoryComponent;