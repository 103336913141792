import React from 'react';
import Griddle, { plugins, RowDefinition, ColumnDefinition } from 'griddle-react';
import { ServiceWorker } from "../../services/ServiceWorker";
import { Table } from 'rsuite';
import {CC_URL} from '../../common/config'
import moment from 'moment';
import ReactModal from 'react-modal';
import "./PayoutTable-styles.css";

const customStyles = {
    content : {
      top                   : '45%',
      left                  : '58%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      maxHeight             :'518px',
      width                 :'75%',
      minHeight             :"518px",
    }
  };

class PayoutTableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            showDropdown: false,
            campaignOpened:false,
            detailedPayout:[],
            tempDetailed:[],
            showModal:false
        }
      
    }

    async componentDidMount() {
        this.renderData();
    }

    renderData() {
        let data = this.props.payoutData;
        let today = new Date();
        let todayDate = today.toDateString().slice(3);
        if(data.length>0){
            data.map(elem=>{
                elem.tempMerchant = elem.merchantUserID
                elem.paymentLink = elem.merchantUserID
                elem.stopPayment = elem.merchantUserID
            })
        }
        let detailedPayout=this.props.payoutDataFull;
        if(detailedPayout.length>0){
            detailedPayout.map(elem=>{
                elem.stopPayment = elem.merchantUserID
            })
        }
        let tempDetailed = detailedPayout
        console.log('payout Data ', data)
        console.log("tempDetailed ",tempDetailed)
        this.setState({
            data: data,
            detailedPayout:detailedPayout,
            tempDetailed:tempDetailed,
            todayDate:todayDate
        });
    }


    componentDidUpdate(prevProps,prepState) {
        // Typical usage (don't forget to compare props):
        if ((this.props.payoutData !== prevProps.payoutData)) {
            this.renderData();
        }
    }


  
    async viewMerchant(merchant){
        var merchantID = merchant
        console.log(merchantID)
         window.open(`${CC_URL}/payoutview/${merchant}`, '_blank').focus();
    }

    async closeModal(){
        await this.setState({
            showModal:false
        })   
    }

    async payAmount(merchantUserID){
        console.log(merchantUserID)
        // from merchantUserID filter payout_ID and total amount from this.state.data .
        // from merchantUserID get all voucher ids from this.state.detailedPayout.
        let merchantData = this.state.data;
        let merchantDataFiltered = merchantData.filter(function (elem) {
            if(elem.merchantUserID==merchantUserID){
                return elem;
            }
        })
        let amount = merchantDataFiltered[0].Total;
        let connectedAccount = merchantDataFiltered[0].payoutID;

        let fullDetail = this.state.detailedPayout;
        let voucherIDs = fullDetail.filter(function (elem) {
            if(elem.merchantUserID==merchantUserID){
                return elem.id;
            }
        })
        voucherIDs=voucherIDs.map(elem=>{
            return elem.id
        })
        console.log("voucherIDs=",voucherIDs)
        let payoutResponse = await ServiceWorker.processPayout(connectedAccount,amount,voucherIDs);
        console.log(payoutResponse)
        if(payoutResponse.status==true){
            window.location.reload(true);
        }
    }

    async stopPayment(merchantUserID){
        let fullDetail = this.state.detailedPayout;
        let voucherIDs = fullDetail.filter(function (elem) {
            if(elem.merchantUserID==merchantUserID){
                return elem.id;
            }
        })
        voucherIDs=voucherIDs.map(elem=>{
            return elem.id
        })
        console.log("voucherIDs=",voucherIDs)
        let payoutResponse = await ServiceWorker.stopPayment(voucherIDs);
        console.log(payoutResponse)
        if(payoutResponse.status==true){
            window.location.reload(true);
        }
    }





    render() {
        const { loading, displayLength, page, } = this.state;
        const CustomHeading = ({ title }) => <span style={{ color: 'black' }}>{title}</span>;
        const CustomColumn = ({ value }) => <span style={{ textAlign: 'left' }}>{value}</span>;
        // const customActivate = ({value}) => <a 
        // onClick={(e)=>this.activate(value)} >
        // <button style={{cursor:'pointer' }}>Activate</button></a>
        const sendMail = ({ value }) => <a
            onClick={(e) => this.activate(value)} >
            <button style={{ cursor: 'pointer' }}>Send alert</button></a>

        console.log('render sectoin campaignOpened',this.state.campaignOpened)
       
        const customActivateDisabled = ({ value }) => <a
        key={value}
        onClick={(e) => alert('please review campaign using view first.')} >
        <button style={{ cursor: 'pointer' }}>Disabled</button></a>
    

        const visitCampaign = ({ value }) => <a
            onClick={(e) => this.viewMerchant(value)} >
            <button style={{ cursor: 'pointer',float:'inherit' }}>view details</button></a>
       
       const pay = ({ value }) => <a
        onClick={(e) => this.payAmount(value)} >
        <button style={{ cursor: 'pointer' ,float:'inherit'}}>Pay</button></a>

        const stop = ({ value }) => <a
        onClick={(e) => this.payAmount(value)} >
        <button style={{ cursor: 'pointer' ,float:'inherit'}}>Stop</button></a>


        return (
            <div className='purchase-history-body' style={{ display: "flex", background: "white" }}>
                <div className='purchase-history-table' style={{ width: "100%" }}>
                    <h3>Pending Payouts for {this.state.todayDate}</h3>
                    <Griddle
                        data={this.state.data}
                        plugins={[plugins.LocalPlugin]}
                    >
                        <RowDefinition >
                        <ColumnDefinition id="email" title="Merchant Email" customHeadingComponent={CustomHeading} customComponent={CustomColumn} />                         
                            <ColumnDefinition id="merchantUserID" title="Merchant ID" customHeadingComponent={CustomHeading} customComponent={CustomColumn} />                         
                            <ColumnDefinition id="payoutID" title="Stripe Account" customHeadingComponent={CustomHeading} /> 
                            <ColumnDefinition id="Total" title="Total Amount" customHeadingComponent={CustomHeading} /> 
                            <ColumnDefinition id="tempMerchant" title="View Complete Payments" customHeadingComponent={CustomHeading} customComponent={visitCampaign} />
                            <ColumnDefinition id="paymentLink" title="Pay" customHeadingComponent={CustomHeading} customComponent={pay} />
                            <ColumnDefinition id="stopPayment" title="Stop" customHeadingComponent={CustomHeading} customComponent={stop} />

                            {/* <ColumnDefinition id="id" title="Action" customHeadingComponent={CustomHeading} customComponent={this.state.campaignOpened===true?customActivate:customActivateDisabled} /> */}
                            
                        </RowDefinition>
                    </Griddle>
                    <ReactModal 
                    isOpen={this.state.showModal}
                    contentLabel="Detailed Payout View"
                    style={customStyles}
                    >
                   <div className="payout-table"> 
                   <button className="common-button" 
                   onClick={(e)=>this.closeModal(e)}
                   >Close</button>
                   <Griddle
                        data={this.state.tempDetailed}
                        plugins={[plugins.LocalPlugin]}
                    >
                        <RowDefinition >
                            <ColumnDefinition id="merchantUserID" title="Merchant ID" customHeadingComponent={CustomHeading} customComponent={CustomColumn} />
                            {console.log("this.state.campaignOpened-> ",this.state.campaignOpened)}                           
                            <ColumnDefinition id="payoutID" title="Stripe Account" customHeadingComponent={CustomHeading} /> 
                            <ColumnDefinition id="campaignID" title="campaignID" customHeadingComponent={CustomHeading} /> 
                            <ColumnDefinition id="optionID" title="optionID" customHeadingComponent={CustomHeading} /> 
                            <ColumnDefinition id="redeemID" title="redeemID" customHeadingComponent={CustomHeading} /> 
                            <ColumnDefinition id="cost" title="cost" customHeadingComponent={CustomHeading} /> 
                            <ColumnDefinition id="updatedAt" title="updatedAt" customHeadingComponent={CustomHeading} />                            
                            <ColumnDefinition id="stopPayment" title="Stop Payment" customHeadingComponent={CustomHeading} customComponent={stop} />

                            {/* <ColumnDefinition id="tempMerchant" title="View Complete Payments" customHeadingComponent={CustomHeading} customComponent={visitCampaign} /> */}
                            {/* <ColumnDefinition id="id" title="Action" customHeadingComponent={CustomHeading} customComponent={this.state.campaignOpened===true?customActivate:customActivateDisabled} /> */}
                            
                        </RowDefinition>
                    </Griddle>
                   </div>
                </ReactModal>
                </div>
            </div>
        )
    }


}
export default PayoutTableComponent;