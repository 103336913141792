import axios from 'axios';
import { API_URL,DEV_PYTHON_SERVICE_URL,RECAPTCHA_KEY,GEO_KEY,CMS_URL ,SCH_URL} from '../common/config';
import {CommonFunctions} from '../common/CommonFunctions';
import moment from 'moment';
//import { EmailService } from './EmailService';

moment().format();

export class ServiceWorker {
    static async sleep(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
          currentDate = Date.now();
        } while (currentDate - date < milliseconds);
      }

    static async getCampaignData(userToken){
        const token= await localStorage.getItem('userLogin');
        const campaignRequest= await axios.get(`${API_URL}/campaigns`, {
            headers: {
            Authorization: `Bearer ${token}`
            }
            });
        const campaignResponse= await campaignRequest.data;
        return campaignResponse;
    }

    static async getCampaignByID(id){
        const campaignRequest= await axios.get(`${API_URL}/campaigns/${id}`);
        const campaignResponse= await campaignRequest.data;
        return campaignResponse;
    }

    static async getCampaignByQueryParams(Qparams){
        // console.log(`${API_URL}/campaigns?${Qparams}`);
        const campaignRequest= await axios.get(`${API_URL}/campaigns?${Qparams}`);
        const campaignResponse= await campaignRequest.data;
        return campaignResponse;
    }

    static async getContentData(){
        const token= await localStorage.getItem('userLogin');
        const contentRequest= await axios.get(`${API_URL}/contents`, {
            headers: {
            Authorization: `Bearer ${token}`
            }
            });
        const contentResponse= await contentRequest.data;
        return contentResponse;
    }

    static async getContentByID(id,Token){
        const contentByIDRequest= await axios.get(`${API_URL}/contents/${id}`, {
            headers: {
            Authorization: `Bearer ${Token}`
            }
            });
        const contentByIDResponse= await contentByIDRequest.data;
        return contentByIDResponse;
    }

    static async postContent(data) {
        const token= await localStorage.getItem('userLogin');
        try{
        const responeData = await axios.post(`${API_URL}/contents`,data,{
            headers: {
            Authorization: `Bearer ${token}`
            }
        });
        const returnData= await responeData.data;
        return returnData;
        }
        catch(error) {
               //console.log("error.message", error.message);
               console.log("error data", error.response.data);
               console.log("error status", error.response.data.statusCode);
               let statusCode =error.response.data.statusCode
               return statusCode.toString();
               // return ["400",error.response.data];
            }
    }

    static async registerUser(data) {
        try {
        const registerUserRequest = await axios.post(`${API_URL}/auth/local/register`, {
          username: data.mobileno,
          email: data.emailid,
          password: data.password,
          phone: data.mobileno,
          confirmed: true
        });
        const registerUserResponse = await registerUserRequest.data;
        return ["200",registerUserResponse];
    } catch(error) {
       console.log("error.message", error.message);
        return ["400",error.response.data];
    }
    }

    static async registerVirtualUser(data) {
        try {
            console.log("data =",data);
            console.log("sent= ",data.mobileno,data.password,data.emailid);
        // currently Hardcoded the role. 
        const registerUserRequest = await axios.post(`${API_URL}/register_new`, {
          username: data.mobileno,
          email: data.emailid,
          password: data.password,
          phone: data.mobileno,
        confirmed: true
        });
    
        const registerUserResponse = await registerUserRequest.data;
        return ["200",registerUserResponse];
    } catch(error) {
       console.log("error.message", error.message);
       console.log("error data", error.response.data);
       console.log("error status", error.status);
        return ["400",error.response.data];
    }
    }

    static async makeUserMerchant(userID, token) {
        const makeMerchant = await axios.post(`${API_URL}/publishers`, {
            userID
          }, 
          {
              headers: {
              Authorization: `Bearer ${token}`
              }
          });
        const makeMerchantStatus = await makeMerchant.status;
        return makeMerchantStatus; 
    }

    static async makeUserSubscriber(userID, token) {
        const makeSubscriber = await axios.post(`${API_URL}/subscribers`, {
            userID
          }, 
          {
              headers: {
              Authorization: `Bearer ${token}`
              }
          });
        const makeSubscriberStatus = await makeSubscriber.status;
        return makeSubscriberStatus; 
    }

    static async loginUser(identifier, password) {
        console.log(identifier, password)
        try {
        const loginUserRequest = await axios.post(`${API_URL}/auth/local`, {
          identifier: identifier,
          password: password,
        });
        const loginUserResponse = await loginUserRequest.data;
        return ["200", loginUserResponse];
    } catch(error) {
        console.log('error',error);
        return ["400", error];
    }
    }

    static async forgotPassword(email) {
        console.log("email", email);
        // default-> {API_URL}/auth/forgot-password
        // middleware-> `${API_URL}/forgotPasswordMiddleWare`
        const forgotPasswordRequest = axios.post(`${API_URL}/auth/forgot-password`, {
          email: email,
          url: `${API_URL}/admin/plugins/users-permissions/auth/reset-password`,
        });
        const forgotPasswordResponse = await forgotPasswordRequest.data;
        console.log("forgotPasswordResponse=",forgotPasswordResponse)
        return forgotPasswordResponse;
        
    }

    static async UpdateRole(uid, roleid, token) {
        await axios.put(`${API_URL}/users/${uid}`, {
            "role": roleid
        }, 
        {
            headers: {
            Authorization: `Bearer ${token}`
            }
        });
    }


    static async search(keyword) {
        const searchRequest= await axios.get(`${API_URL}/campaigns?contents.name_contains=${keyword}`);
        const searchResponse= await searchRequest.data;
        return searchResponse;
    }

    static async searchLocation(keyword) {
        const searchRequest= await axios.get(`${API_URL}/campaigns?location.city_contains=${keyword}`);
        const searchResponse= await searchRequest.data;
        return searchResponse;
    }

    static async getAllCategories(){
        const token= await localStorage.getItem('userLogin');
        const categoriesRequest= await axios.get(`${API_URL}/categories`,{
            headers: {
            Authorization: `Bearer ${token}`
            }
        });
        const categoriesResponse= await categoriesRequest.data;
        return categoriesResponse;
    }

    static async updateUserImage(pfID, profileImage, userToken){
        // const updateUserImageRequest=await axios.put(`${API_URL}/user-preferences/${pfID}`,profileImage, {
        //     headers: {
        //     Authorization: `Bearer ${userToken}`
        //     }
        // });
        await axios({
            async: true,
            crossDomain: true,
            processData: false,
            contentType: false,
            mimeType: 'multipart/form-data',
            method: 'POST',
            url: `${API_URL}/upload`,
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlMmE3ZjYzMjU2ODYyMTdhYTZkZWRlOSIsImlzQWRtaW4iOnRydWUsImlhdCI6MTU5NzYzNDgzMCwiZXhwIjoxNjAwMjI2ODMwfQ.Jlr5bNOgRhd8o7HqEKexGoDSq2xpJU0F5ms42-3Zs_Q`,
            },
            data: profileImage
          });
        // const updateUserImageResponse=await updateUserImageRequest.data;
        // return updateUserImageResponse;
    }

    static async uploadFile(data){
        const token= await localStorage.getItem('userLogin');
        try{console.log("uploading file......")
            const responeData = await axios.post(`${API_URL}/upload`,data,{
                headers: {
                Authorization: `Bearer ${token}`
                }
            });
            const returnData= await responeData.data;
            console.log("File uploaded.")
            return returnData;
            }
            catch(error) {
                   
                   console.log("error data", error.response.data);
                   console.log("error status", error.status);
                   // return ["400",error.response.data];
                }
    }

    static async createCampaign(data){
        const token= await localStorage.getItem('userLogin');
        try{console.log("reached campaign api")
        const responeData = await axios.post(`${API_URL}/campaigns`,data,{
            headers: {
            Authorization: `Bearer ${token}`
            }
        });
        const returnData= await responeData.data;
        return returnData;
        }
        catch(error) {
               
               console.log("error data", error.response.data);
               console.log("error status", error.status);
               // return ["400",error.response.data];
            }
    }

    static async getAllLocations(){
        const token= await localStorage.getItem('userLogin');
        const locationRequest= await axios.get(`${API_URL}/addresses`, {
            headers: {
            Authorization: `Bearer ${token}`
            }
            });
        const locationResponse= await locationRequest.data;
      //  console.log("address= ",locationResponse)
        return locationResponse;
    }

    static async updateContent(contentID,data){
        console.table(data)
        const token= await localStorage.getItem('userLogin');

        try{
        const responeData = await axios.put(`${API_URL}/contents/${contentID}`,data,{
            headers: {
            Authorization: `Bearer ${token}`
            }
        });
        const returnData= await responeData.data;
        return returnData;
        }
        catch(error) {
               //console.log("error.message", error.message);
               console.log("error data", error.response.data);
               console.log("error status", error.status);
               // return ["400",error.response.data];
            }
    }

    static async updateCampaign(campaignID,data) {
        try{console.log("reached campaignupdate api")
        const token= await localStorage.getItem('userLogin');
        console.log(campaignID,data)
        const responeData = await axios.put(`${API_URL}/campaigns/${campaignID}`,data,{
            headers: {
            Authorization: `Bearer ${token}`
            }
        });
        const returnData= await responeData.data;
        return returnData;
        }
        catch(error) {
               
               console.log("error data", error.response.data);
               console.log("error status", error.response.data.statusCode);
               return error.response.data.statusCode
               // return ["400",error.response.data];
            }
    }

    static async findPublisherContent(pubQuery,userToken) {
      //  console.log("Publisher  user= ",pubQuery)
        const contentRequest = await axios.get(`${API_URL}/contents?publisherID=${pubQuery}`, {
            headers: {
            Authorization: `Bearer ${userToken}`
            }
            });

        const contentResponse= await contentRequest.data;
        return contentResponse;
    }



    static async getPublisherId(Qparams, jwtToken){
        try {
        const publisherData= await axios.get(`${API_URL}/publishers/?userID=${Qparams}`, {
            headers: {
            Authorization: `Bearer ${jwtToken}`
            }
            });
        const publisherId= await publisherData.data;
        return ["200", publisherId[0]["id"]];
        } catch(error) {
        return ["400",null];
        }
    }

    static async getSubscriberId(Qparams, jwtToken){
        try {
        const subscriberData = await axios.get(`${API_URL}/subscribers/?userID=${Qparams}`, {
            headers: {
            Authorization: `Bearer ${jwtToken}`
            }
            });
        const subscriberId = await subscriberData.data;
        return ["200", subscriberId[0]["id"]];
        } catch(error) {
        return ["400",null];
        }
    }



static async makePayment(chargeToken){
    console.log("reached make payment api", chargeToken);
    try{
        //const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlOGI4NjQyZDQ1NTM4MzI4NWY0YmQ1MiIsImlhdCI6MTU5OTQ4ODk1OCwiZXhwIjoxNjAyMDgwOTU4fQ.CT0D3c4kNu8Fw4KvXPwBymlgu-xdekcg4VQTmdWtOLw";
        const token= await localStorage.getItem('userLogin');
    const responeData = await axios.post(`${API_URL}/charge`,{
        amount: 10*100,
        currency: "usd",
        description: 'Test charge',
        source: chargeToken.tokenId
    },{
        headers: {
        Authorization: `Bearer ${token}`
        }
    });
    const returnData= await responeData.data;
    return ["200",returnData];
    }
    catch(error) {
           
           console.log("error data", error.response.data);
           console.log("error status", error.status);
           return ["400",error.response.data];
        }
}

static async countPublisherCampaigns(pubid){
    const token= await localStorage.getItem('userLogin');
    const countRequest= await axios.get(`${API_URL}/campaigns/count/?publisherID=${pubid}`, {
        headers: {
        Authorization: `Bearer ${token}`
        }
        });
    const countResponse= await countRequest.data;
    return countResponse;
}

static async getAllDealTypes(){
    const token= await localStorage.getItem('userLogin');
    const dealTypeRequest= await axios.get(`${API_URL}/dealtypes`, {
        headers: {
        Authorization: `Bearer ${token}`
        }
        });
    const dealTypeResponse= await dealTypeRequest.data;
    return dealTypeResponse;
}

static async getBusinessDealsLocations()
{   const token= await localStorage.getItem('userLogin');
    const publisherID = await localStorage.getItem('publisherID');
    const bussinessDealLocation= await axios.get(`${API_URL}/bussiness-addresses/?publisherID=${publisherID}`, {
        headers: {
        Authorization: `Bearer ${token}`
        }
        });
    const bussinessDealLocationResponse= await bussinessDealLocation.data;
    return bussinessDealLocationResponse;

}

static async processVideo(contentID,videourl,contentType) {
    

    try {
    console.log("Hitting ",`${DEV_PYTHON_SERVICE_URL}/push_video`);
    console.log("Awaiting Response from video service.")
    const postContent = await axios.post(`${DEV_PYTHON_SERVICE_URL}/push_video`, {
      content_id: contentID,
      url: videourl,
      type:contentType
    }
    );
    const contentResponse = await postContent.data;
    console.log("Response= ",contentResponse)
    
    return ["200",contentResponse];
} catch(error) {

    return ["400","error.contentResponse.data"];
}
}

static async deleteCampaign(campaignIDs) {
    const token= await localStorage.getItem('userLogin');
    for(let i=0;i<campaignIDs.length;i++)
    {   console.log("Deleting ",campaignIDs[i]);
        try {
            const deleteCampaign = await axios.delete(`${API_URL}/campaigns/${campaignIDs[i]}`,{
                headers: {
                Authorization: `Bearer ${token}`
                }
            });
            const deleteResponse = await deleteCampaign.data;
            console.log("Response= ",deleteResponse)
            
        } catch(error) {
        
            return ["400","error.deleteResponse.data"];
        }

    }
    return "200";
}


static async deleteContent(contentIDs) {
    const token= await localStorage.getItem('userLogin');
    for(let i=0;i<contentIDs.length;i++)
    {   console.log("Deleting ",contentIDs[i]);
        try {
            const deleteContents = await axios.delete(`${API_URL}/contents/${contentIDs[i]}`,{
                headers: {
                Authorization: `Bearer ${token}`
                }
            });
            const deleteResponse = await deleteContents.data;
            console.log("Response= ",deleteResponse)
            
        } catch(error) {
        
            return ["400","error.deleteResponse.data"];
        }

    }
    return "200";
}

static async isUsernameRegistered(userId)
{   console.log("Check called.= ",userId);

    if(userId.length>=10){
        try{
        let userID = userId;
        console.log("userID=",userID)
        const campaignRequest= await axios.post(`${API_URL}/isuserregistered`,{
            user:userID
        });
        console.log("Recvd Data= ",campaignRequest.data.status)
        if(campaignRequest.data.status==='true')
        {console.log("User alredy registered.")
            return 1;
        }else{
            return 0;
        }
        }
        catch(error)
        {
            console.log("error.message", error.message);
            return 0;
        }
    }
}

static async captchaVerification(tokenRcvd)
{
    let humanKey = tokenRcvd;
    let RECAPTCHA_SERVER_KEY ={RECAPTCHA_KEY};
    const isHuman = await axios.post(`${API_URL}/findrole`, {
        role:humanKey
    });
    if (humanKey === null || !isHuman) {
    return 0;
    }

    // The code below will run only after the reCAPTCHA is succesfully validated.
    console.log("SUCCESS!")
    return 1;
}

static async userInformation()
{
    const token= await localStorage.getItem('userLogin');
    try{
        const userInfoResponse= await axios.get(`${API_URL}/users/me`, {
            headers: {
            Authorization: `Bearer ${token}`
            }
            });
        return userInfoResponse.data;
    }catch(err)
    {
        return false;
    }
    
}

static async isLoggedIn(){
    let status = await this.userInformation();
    console.log("status=",status)
    if(status==false){
        return false;
    }
    return true;
}

static async getGeoInformationByStreet(street){
    var st = street;
    console.log("my street=",st);
    try{
        const getGeoData = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${st}&key=${GEO_KEY}`)
       let obj = {'lat':getGeoData.data.results[0].geometry.location.lat,"lng":getGeoData.data.results[0].geometry.location.lng};
        console.log("getGeoData=",getGeoData)
        return obj;
    }
    catch(e)
    {
        console.log(e);
        return {'lat':"","lng":""};
    }

}

static async createBusinessAddress(data){
    const token= await localStorage.getItem('userLogin');
    try{
        const pushData = await axios.post(`${API_URL}/bussiness-addresses`,data,{
            headers: {
            Authorization: `Bearer ${token}`
            }
        });
        console.log(pushData);
        return 1;
    }catch(e)
    {
        return 0;
    }
}

static async updateBusinessAddress(data,id){
    console.log("---------Updating business address------------------")
    console.log("id to update=",id)
    const token= await localStorage.getItem('userLogin');
    try{
        const pushData = await axios.put(`${API_URL}/bussiness-addresses/${id}`,data,{
            headers: {
            Authorization: `Bearer ${token}`
            }
        });
        console.log(pushData);
        return 1;
    }catch(e)
    {
        return 0;
    }
}

static async deleteBusinessAddress(id)
{
    const token= await localStorage.getItem('userLogin');
    const publisherID = await localStorage.getItem('publisherID');
    //check if there is any campaign associated with that b-address.
    const campaignRequest= await axios.get(`${API_URL}/campaigns/?status=active&publisherID=${publisherID}`, {
        headers: {
        Authorization: `Bearer ${token}`
        }
        });
    var businessAddresses = [];
    console.log("campaignRequest=",campaignRequest)
    if(campaignRequest.data.length>0)
    {
        campaignRequest.data.forEach(element=>{
            if(element.bussinessAddresses.length>0)
            {
                element.bussinessAddresses.forEach(businessObject=>{
                    businessAddresses.push(businessObject.id)
                })
            }
        })
        console.log("Rcvd=",id);
        console.log("All=",businessAddresses)
        if(businessAddresses.includes(id))
        {
            //return pop-up that active campaign b-addess cant be deleted.
            return 0;
        }
        else{
            try{
                const pushData = await axios.delete(`${API_URL}/bussiness-addresses/${id}`,{
                    headers: {
                    Authorization: `Bearer ${token}`
                    }
                });
                
                return 1;
            }catch(e)
            {
                return 0;
            }

        }

    }else{
        //delete
        try{
            const pushData = await axios.delete(`${API_URL}/bussiness-addresses/${id}`,{
                headers: {
                Authorization: `Bearer ${token}`
                }
            });
            
            return 1;
        }catch(e)
        {
            return 0;
        }

    }
    


}

static async resetPassword(old,newPwd) {
    // check if the old password is correct or not.
    const username= await localStorage.getItem('user');

    let authCheck = await this.loginUser(username,old);
    if(authCheck[0]!="200")
    {
        console.log("old password entered incorrectly.");
        return {'status':false,'message':'Old password is incorrect.'}
    }
    else{
         // if yes reset new password, else , throw auth error.
        try{    
                let token = authCheck[1]['jwt'];
                let uid = authCheck[1]['user'].id;
                const responeData = await axios.put(`${API_URL}/users/${uid}`,{'password':newPwd},{
                    headers: {
                    Authorization: `Bearer ${token}`
                    }
                }); 
                //send notification email.
                const publisher = await localStorage.getItem('publisherID');
                try{
                    let sendMail =await axios.post(`${API_URL}/trigger-email`, {
                        "cc":"",
                        "publisherID":publisher,
                        "isPublisher":true,
                        "emailType":"passwordchange"
                      },{
                        headers: {
                        Authorization: `Bearer ${token}`
                        }
                    });
                }catch(e)
                {
                    console.log("email error=",e)
                }

                return {'status':true,'message':'Password changed successfully.'};
        }
        catch(e)
        {
            console.log(e);
            return {"status":false,'message':'An error occurred while changing password.'}

        }
    }
   
}

static async AddPaymentMethod(account,route)
{   console.log("Reached",account,route)
    const token= await localStorage.getItem('userLogin');
    let payLoad = [{
        'name':'bankAccount',
        'number':account,
        'routingnumber':route
    }];
    try{
        let userInfo = await this.userInformation(token);
        let uid = userInfo.id
        const responeData = await axios.put(`${API_URL}/users/${uid}`,{'paymentMethod':payLoad},{
            headers: {
            Authorization: `Bearer ${token}`
            }
        }); 
      //  console.log("responeData=",responeData)
        return {"status":true,"message":"Bank information added successfully"}
    }catch(e)
    {
        return {"status":false,"message":"Unable to add bank information, please try again after some time"}
    }

}

static async getCampaignHistory()
{
    const publisher = await localStorage.getItem('publisherID');
    let query = "publisherID="+publisher
    const campaigns = await this.getCampaignByQueryParams(query);
    let filter = CommonFunctions.RemoveKeysFromArrayOfObjects(campaigns,['id','name','status','shareCount','viewCount','boughtCount','createdAt','updatedAt']);
    let removeDrafts = [];
    if(filter.length>0)
    {
        filter.forEach(elems=>{
            if(elems.status!='onHold')
            {   
                elems.updatedAt = moment(elems.updatedAt).format('MM-DD-YYYY')
                elems.createdAt = moment(elems.createdAt).format('MM-DD-YYYY')
                removeDrafts.push(elems);
            }
        })
    }

    return removeDrafts.reverse();
}


static async voucherMethod(type)
{
    const token= await localStorage.getItem('clientUserLogin');
    const publisher = await localStorage.getItem('publisherID');
    var voucherResponse= await axios.get(`${API_URL}/vouchers/?publisherID=${publisher}`, {
        headers: {
        Authorization: `Bearer ${token}`
        }
        });
    let query = "publisherID="+publisher

    const campaigns = await this.getCampaignByQueryParams(query);
    
    let filter = CommonFunctions.RemoveKeysFromArrayOfObjects(campaigns,['id','name']);
    voucherResponse = voucherResponse.data
    voucherResponse.forEach(elems=>{
        filter.forEach(campaign=>{
            
            if(elems.campaignID==campaign.id)
            {  // console.log("yes")
                elems.campaignid = elems.campaignID;
                elems.campaignID=campaign.name;
                elems.updatedAt = moment(elems.updatedAt).format('MM-DD-YYYY')
            }
        })
    });
    switch(type)
    {
        case 'purchases':
            // console.log("reached voucher method");
            // console.log("campaigns=",filter)
            
            // console.log("voucherResponse=",voucherResponse);
            
            // console.log("voucherResponse=",voucherResponse)
            const keys_to_keep = ['cost', 'userID','campaignID','campaignid','updatedAt'];
            voucherResponse = CommonFunctions.RemoveKeysFromArrayOfObjects(voucherResponse,keys_to_keep);
            
            return voucherResponse;
            break;
        
        case 'ledgers':
              return voucherResponse;
              break;
        default:
            break;
    }

}

static async getPublisherNotificationPreference()
{
    const token= await localStorage.getItem('userLogin');
    const publisher = await localStorage.getItem('publisherID');
    
    let publisherResponse = await axios.get(`${API_URL}/publishers/${publisher}`, {
        headers: {
        Authorization: `Bearer ${token}`
        }
        });
    var notificationPreference = publisherResponse.data.notifications;
    if(notificationPreference.length<1)
    {
        return 0;
    }else{
        // notificationTypes is kept here just for reference of type of notifications .
        let notificationTypes = ['passwordChange','emailChange','accountChange','onPurchase','onRedeem'];
        var i=0;
        notificationPreference.forEach(types=>{
            if(notificationPreference[i].name.toLowerCase()==='forgetpassword')
            {
                notificationPreference.splice(i, 1); 
            }
            if(!types.hasOwnProperty('enable'))
            {
                types['enable']=false;   
            }
            i=i+1;
        })
        return notificationPreference;
    }
}

static async updateNotificationPreference(payload){
    console.log("Reached update api")
    console.log(payload)
    const token= await localStorage.getItem('userLogin');
    const publisher = await localStorage.getItem('publisherID');
    try{
        const responeData = await axios.put(`${API_URL}/publishers/${publisher}`,payload,{
            headers: {
            Authorization: `Bearer ${token}`
            }
        });
        const returnData= await responeData.data;
        return returnData;
        }
        catch(error) {
               //console.log("error.message", error.message);
               console.log("error data", error.response.data);
               console.log("error status", error.status);
               // return ["400",error.response.data];
            }

}

static async getSubscriptionHistory(){
    const token= await localStorage.getItem('userLogin');
    const publisher = await localStorage.getItem('publisherID');

    const subscriptionData= await axios.get(`${API_URL}/susbcriptions/?publisherID=${publisher}`, {
        headers: {
        Authorization: `Bearer ${token}`
        }
        });
    let subscriptionDataResponse= subscriptionData.data;
    if(subscriptionDataResponse.length>0)
    {
        subscriptionDataResponse.forEach(elem=>{
            elem.startdate = moment(elem.startdate).format('MM-DD-YYYY');
            elem.renewdate = moment(elem.renewdate).format('MM-DD-YYYY');
        })
    }
    
    return subscriptionDataResponse;
}

static async getPayoutHistory(){
    const token= await localStorage.getItem('userLogin');
    const publisher = await localStorage.getItem('publisherID');

    const payoutResponseData= await axios.get(`${API_URL}/payouts/?merchantID=${publisher}`, {
        headers: {
        Authorization: `Bearer ${token}`
        }
        });
    let payoutResponseDataResponse= payoutResponseData.data;
    if(payoutResponseDataResponse.length>0)
    {
        payoutResponseDataResponse.forEach(elem=>{
            elem.date = moment(elem.date).format('MM-DD-YYYY');
        })
    }
    
    return payoutResponseDataResponse;
}

static async getAllPlans()
{
    const payoutResponseData= await axios.get(`${API_URL}/sub-options`);

    let responseData = payoutResponseData.data;
    return responseData;

}

static async getDevices(type)
{
    const token= await localStorage.getItem('userLogin');
    console.log(token)
   // const publisher = await localStorage.getItem('publisherID');
    
        try{
            const activeDevices = await axios.get(`${API_URL}/devices/?deviceState=${type}`, {
                headers: {
                Authorization: `Bearer ${token}`
                }
                });

            return activeDevices.data;
        }
        catch(e)
        {
            console.log(e);
            return [];
        }
   
}
static async getDevice(id)
{
    const token= await localStorage.getItem('userLogin');
    console.log(token)
   // const publisher = await localStorage.getItem('publisherID');
    
        try{
            const activeDevices = await axios.get(`${API_URL}/devices/${id}`, {
                headers: {
                Authorization: `Bearer ${token}`
                }
                });

            return activeDevices.data;
        }
        catch(e)
        {
            console.log(e);
            return [];
        }
   
}

static async sendCampaignNotification(data){
    console.log("Sending email notification.")
    const token = await localStorage.getItem('userLogin');
    var data={
        "campaignName":data.name,
        "campaignId":data.id,
        "publisherId":data.publisherID
    }

    try{
        const responeData = await axios.post(`${API_URL}/trigger-campaign-notification`,data,{
            headers: {
            Authorization: `Bearer ${token}`
            }
        });
        return responeData.data;

    }catch(e){
        console.log(e);
        return false;
    }
}

static async resetUserPassword(user){
    let data={
        "user":user
    }
    try{
        const responeData = await axios.post(`${API_URL}/resetCred`,data);
        console.log(responeData.data.user)
        await this.forgotPassword(responeData.data.user)

        return true;

    }catch(e){
        console.log(e);
        return false;
    }
}

static async loginClient(username,toClose,isUserID){
   // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwNDY0MzY2NzdlODZiMGU5ZjYwNjg5NiIsImlhdCI6MTYxNTIyNzcxNSwiZXhwIjoxNjE3ODE5NzE1fQ.X8geolOGOb3MjLaBasIifaVtS-dPbczCjWkBICKme10';
    const token = await localStorage.getItem('userLogin');

    // if(CommonFunctions.isValidPhoneNumber(username)){
    //     console.log("search userID & publisher ID by phone no. ");
    // }
    let searchType='publisher';
    console.log("ttest ",CommonFunctions.isValidPhoneNumber(username))
    if(await CommonFunctions.isValidPhoneNumber(username)==true){
        console.log("setting phone type")
        searchType='phone';
    }
    else if(await CommonFunctions.isEmailValid(username)==true){
        searchType='email';
    }
    console.log("hitting new user support api searchType=",searchType)
    if(isUserID){
        searchType='userID'
    }
    const userInfo = await axios.post(`${API_URL}/userSupportInfo`, {
        user: username,
        type:searchType
      },{
        headers: {
        Authorization: `Bearer ${token}`
        }
    });
    
    console.log("userInfo-> ",userInfo)
    if(userInfo.data.length>0){
        console.log("userInfo.data[0]= ",userInfo.data)
        var email = userInfo.data[0].data.email;
        var usname = userInfo.data[0].data.usname;
        var user = userInfo.data[0].data.user;
        var userType = 'merchant';
        var userID = userInfo.data[0].data.userID;
        var userLogin = userInfo.data[0].data.userLogin;
        var publisherID = userInfo.data[0].data.publisherID
        
       await localStorage.setItem("clientUserLogin",userLogin);
       await localStorage.setItem("publisherID", publisherID);

    }

    if(userID!=undefined){
        let url = `${CMS_URL}/campaign?userID=${userID}&name=${usname}&user=${user}&userType=${userType}
    &userID=${userID}&userLogin=${userLogin}&publisherID=${publisherID}&email=${email}&supportLogin=${token}`
    var win = window.open(url);
    win.focus();
    if(toClose===true){
        this.sleep(5000);
        //win.close()
    }
    }else{
        alert("No results found!");
        localStorage.removeItem('publisherID');
        localStorage.removeItem('clientEmail');
        localStorage.removeItem('clientUserLogin');
        return false;
    }
    
  return userInfo.data;
}

 static async getMerchantDashboardData(){
    const token = await localStorage.getItem('userLogin');

    try{
        let data = await axios.get(`${API_URL}/merchantdashboarddata`,{
            headers: {
            Authorization: `Bearer ${token}`
            }
        });
        return data.data;
    }catch(e) {
        console.log(e);
        return false;
    }
 }

 static async changeCampaignStatus(id,campaignStatus){
    const token = await localStorage.getItem('userLogin');

     if(campaignStatus!=undefined){
        try{
            const responeData = await axios.put(`${API_URL}/campaigns/${id}`,{
                status:campaignStatus
            },{
                headers: {
                Authorization: `Bearer ${token}`
                }
            }); 
            return {'status':true,'data':responeData.data}; 
        } catch(e){
            console.log(e);
            return {'status':false};
        }
     }
 }

 static async getPayoutData(){
     console.log("Calling payout")
    const token = await localStorage.getItem('userLogin');

    let today = new Date()
    let yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate()-1) // one day back
    console.log('yesterday = ',yesterday)
    let yesterday2=yesterday.toISOString() // convert date to ISO format.
    console.log('yesterday2 = ',yesterday2)
    console.log("searching data for ",yesterday2);
    // yesterday2 variable replaced with all.
    let vouchers = await axios.post(`${API_URL}/todaysvouchers`, {
        query: 'all'
      },{
        headers: {
        Authorization: `Bearer ${token}`
        }
    });
    console.log("vouchers ",vouchers)
    let columnsToKeepInVouchers=['id','publisherID','campaignID','optionID','cost','redeemID','createdAt','updatedAt','userID'];
    vouchers = await CommonFunctions.RemoveKeysFromArrayOfObjects(vouchers.data,columnsToKeepInVouchers)
    vouchers=vouchers.filter(function (elem){
        if(elem.redeemID!=undefined) return elem;
    })
    let merchantList = [];
    vouchers.forEach(elem=>{
        if(!merchantList.includes(elem.publisherID)){
                merchantList.push(elem.publisherID);
        }
    })
    console.log("Merchant list= ",merchantList);

    let allPublishers = await axios.get(`${API_URL}/publishers`,{
        headers: {
        Authorization: `Bearer ${token}`
        }
    });
    let filteredPublishers = allPublishers.data.filter(function (elem){
        if(merchantList.includes(elem.id)){
            return elem;
        }
    })
    filteredPublishers = await CommonFunctions.RemoveKeysFromArrayOfObjects(filteredPublishers,['id','userID']);

    vouchers.map(elem=>{
        filteredPublishers.forEach(element=>{
            if(element.id === elem.publisherID){
                elem['merchantUserID'] =element.userID;
            }
        })
    })

    console.log(' filteredPublishers ',filteredPublishers);
    let MerchantUserID_list =[]
     filteredPublishers.forEach(function (elem){
        MerchantUserID_list.push(elem.userID);
    })
    MerchantUserID_list= Array.from(new Set(MerchantUserID_list));
    console.log('MerchantUserID_list',MerchantUserID_list);
    console.log("vouchers = ",vouchers)
    // userinformation 
    var merchantInformation =[]
    // fetch each user Data.
    var paymentMerchantRelation=[]
    await Promise.all(
       await MerchantUserID_list.map(async (user)=>{
            let data = await axios.get(`${API_URL}/users/${user}`,{
                headers: {
                Authorization: `Bearer ${token}`
                }
            });
             let result =data.data;
             merchantInformation.push(result);
             console.log("result ",result);
             let paymentMethod = result.paymentMethod;
             console.log('paymentMethod',paymentMethod)
             await paymentMethod.map(async (methods)=>{
                 if(methods.payoutMethod=='stripe'){
                    paymentMerchantRelation.push({'merchantUserID':user,'payoutID':methods['payoutID']})
                 }
             })
        })
    )
    
    console.log("paymentMerchantRelation ",paymentMerchantRelation)
    vouchers.map(elems=>{
        paymentMerchantRelation.map(relation=>{
            console.log('elem= ',elems,'rekation ',relation)
            console.log("printing relation")
            console.log(relation.merchantUserID ,elems.merchantUserID )
            if(relation.merchantUserID == elems.merchantUserID){
                console.log('found')
                elems['payoutID']=relation.payoutID;
            }
        })
    })
    console.log('vouchers 1',vouchers)
    // vouchersToShowDict will contain total amount each merchant have to be paid.
    let vouchersToShowDict = {};
    let columnsToKeepInVouchers1=['merchantUserID','payoutID','cost'];
    let vouchersToShowTemp = await CommonFunctions.RemoveKeysFromArrayOfObjects(vouchers,columnsToKeepInVouchers1)
    vouchersToShowTemp.map(function (elem){
        vouchersToShowDict[elem.merchantUserID]=vouchersToShowDict.hasOwnProperty(elem.merchantUserID)?vouchersToShowDict[elem.merchantUserID]+elem.cost:elem.cost;

    })

    console.log("vouchersToShowDict ",vouchersToShowDict)
    vouchersToShowTemp = await CommonFunctions.RemoveKeysFromArrayOfObjects(vouchers,['merchantUserID','payoutID'])
    // Remove duplicate
    vouchersToShowTemp = await CommonFunctions.removeDuplicates(vouchersToShowTemp,'merchantUserID')
    
    vouchersToShowTemp.map(function (elem){
        elem['Total'] = vouchersToShowDict[elem.merchantUserID]
    })
    
    let emailRelation ={}
    merchantInformation.map(elem=>{
        emailRelation[elem.id]=elem.email
    })
    console.log("emailRelation",emailRelation)
    vouchersToShowTemp.map(element=>{
        console.log("found ==",element.merchantUserID,emailRelation[element.merchantUserID])
        element.email = emailRelation[element.merchantUserID]
    })

    vouchers.map(elem=>{
        elem.updatedAt = moment(elem.updatedAt).format('MM-DD-YYYY')
        elem.email = emailRelation[elem.merchantUserID]
    })
    
    return {'status':true,'vouchersToShowTemp':vouchersToShowTemp,'full':vouchers};

 }

 static async processPayout(connectedAccount,amount,voucherIDs){
    const token = await localStorage.getItem('userLogin');
    console.log("reached processPayout")
    //let API_URL = 'http://127.0.0.1:1337'
    let payoutResponse = await axios.post(`${API_URL}/processPayout`, {
        id: connectedAccount,
        amt:amount,
        vouchers:voucherIDs
      },{
        headers: {
        Authorization: `Bearer ${token}`
        }
    });
    console.log("payoutResponse=",payoutResponse.data)
    return payoutResponse.data;
 }

 static async stopPayment(voucherIDs){
    const token = await localStorage.getItem('userLogin');
    console.log("reached processPayout")
   // let API_URL = 'http://127.0.0.1:1337'
    let stopResponse = await axios.post(`${API_URL}/stoppayment`, {
        vouchers:voucherIDs
      },{
        headers: {
        Authorization: `Bearer ${token}`
        }
    });
    console.log("stopResponse=",stopResponse.data)
    return stopResponse.data;
 }

 static async getRedeemedVoucherByUserID(userID){
     console.log("called voucherResponse")
    const token = await localStorage.getItem('userLogin');
    var voucherResponse= await axios.get(`${API_URL}/vouchers/?userID=${userID}&status=redeemed`, {
        headers: {
        Authorization: `Bearer ${token}`
        }
        });
    
    return voucherResponse.data;

 }

 static async initiateRefund(payload,voucherID){
    const token = await localStorage.getItem('userLogin');
    var voucherID = voucherID
    console.log("voucherID=",voucherID)
    //let API_URL = 'http://127.0.0.1:1337'
    let refundResponse = await axios.post(`${API_URL}/initiaterefund`, payload,{
        headers: {
        Authorization: `Bearer ${token}`
        }
    });
    console.log("refundResponse=",refundResponse.data)
    if(refundResponse.data.status==true){
        //update voucher as refunded.
        console.log("calling api to mark refund= ",voucherID)
        try{
            let voucherResponse = await axios.put(`${API_URL}/vouchers/${voucherID}`, {
                'status':'refunded'
            },{
                headers: {
                Authorization: `Bearer ${token}`
                }
            });
            console.log("voucherResponse=",voucherResponse)
        }catch(e){
            alert("Amount refunded but voucher status not updated as refunded.")
        }
    }else{
        alert(refundResponse.data.message.code)
    }
    return refundResponse.data;
 }

 static async getUserID(username){
    const token = await localStorage.getItem('userLogin');
    var searchType = 'phone'
    if(await CommonFunctions.isValidPhoneNumber(username)==true){
        console.log("setting phone type")
        searchType='phone';
    }
    else if(await CommonFunctions.isEmailValid(username)==true){
        searchType='email';
    }
    console.log("hitting new user support api searchType=",searchType)

    const userInfo = await axios.get(`${API_URL}/users?${searchType}=${username}`,{
        headers: {
        Authorization: `Bearer ${token}`
        }
    });
    
    console.log("userInfo-> ",userInfo)
    return userInfo.data[0].id;
 }

 static async getDeviceFloatingTexts(){
    const token = await localStorage.getItem('userLogin');
    try{
        let texts = await axios.get(`${API_URL}/device-floating-texts`,{
        headers: {
        Authorization: `Bearer ${token}`
        }
    }); 
    let count=1;
    texts.data.map(elem=>{
        elem['open']=elem.id
        //elem['qrcode']=elem.qrcode==true?'ON':'OFF'
        try{elem['qrurl']=elem.qrurl.length>0?elem.qrurl:"-"}catch(e){elem['qrurl']="-"}
        try{elem['floatingtext']=elem.floatingtext.length>0?elem.floatingtext:'-'}catch(e){elem['floatingtext']="-"}
        try{elem['specialEffectText']=elem.specialEffectText.length>0?elem.specialEffectText:"-"}catch(e){elem['specialEffectText']="-"}
        try{elem['duration']=elem.duration*0.001}catch(e){elem['duration']=1};
        elem.id = count
        count++;
    })
    console.log("Texts=",texts.data)
    return {'status':true,'data':texts.data}
    }catch(e){
        console.log("err=",e)
        return {'status':false,'data':[]}
    }
 }

 static async postDevice(type,data){
     console.log("postDevice data",data);
     const token = await localStorage.getItem('userLogin');
     switch(type){
         case 'update':
            try{
                let updateResponse = await axios.put(`${API_URL}/device-floating-texts/${data._id}`,
                data,{
                    headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            console.log("updateResponse=",updateResponse.data)
            return {'status':true,'data':updateResponse.data}
            }catch (e) {
                console.log("Error updating device floating",e);
                return {'status':false}
            }
        case 'add':
            delete data._id;
            try{
                let updateResponse = await axios.post(`${API_URL}/device-floating-texts`,
                data,{
                    headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            console.log("updateResponse=",updateResponse.data)
            return {'status':true,'data':updateResponse.data}
            }catch (e) {
                console.log("Error updating device floating",e);
                return {'status':false}
            }

         default:
             return
     }
 }
 static async triggerDevice(data) {
    const token= await localStorage.getItem('userLogin');
    try{
    const responeData = await axios.post(`${API_URL}/alertdevice`,data,{
        headers: {
        Authorization: `Bearer ${token}`
        }
    });
    const returnData= await responeData.data;
    return returnData;
    }
    catch(error) {
           //console.log("error.message", error.message);
           console.log("error data", error.response.data);
           console.log("error status", error.response.data.statusCode);
           let statusCode =error.response.data.statusCode
           return statusCode.toString();
           // return ["400",error.response.data];
        }
}

static async getPlaylist(id) {
    const token= await localStorage.getItem('userLogin');

    try{
        let updateResponse = await axios.get(`${API_URL}/playlist?requestID=${id}`,
        {headers: {
            Authorization: `Bearer ${token}`
            }
    }
    )
    //console.log("updateResponse=",updateResponse.data.data)
    updateResponse.data[0].data.map(elem=>{
        delete elem.campaignInfo
    })
    return {'status':true,'data':updateResponse.data}
    }catch (e) {
        console.log("Error updating device floating",e);
        return {'status':false}
    }
}

static async removeFile(id){
    const token= await localStorage.getItem('userLogin');

    let updateResponse = await axios.post(`${API_URL}/removefile`,{
        "id":id
    },
        {headers: {
            Authorization: `Bearer ${token}`
            }
    }
    )
}

static async updateDevice(device,data){
    const token= await localStorage.getItem('userLogin');

    try{
        let updateResponse = await axios.put(`${API_URL}/devices/${device}`,
        data,{
            headers: {
            Authorization: `Bearer ${token}`
        }
    })
    console.log("updateResponse=",updateResponse.data)
    return {'status':true,'data':updateResponse.data}
    }catch (e) {
        console.log("Error updating device ",e);
        return {'status':false}
    }
}

static async dateMoreThanTenMinutes(data_from_database) {
    var date = new Date();
    //date from database
    console.log("db: " + data_from_database)
    console.log("current date: " + date)

    var TEN_MIN=10*60*1000;

    if((date - new Date(data_from_database)) > TEN_MIN) {
    console.log('Delayed by more than 10 mins');
    return true
    }
    return false
}

}

