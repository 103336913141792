import React from "react";
import {API_URL,GEO_KEY} from "../../common/config";
import './DashboardPage-style.css';
//import GoogleMapReact from 'google-map-react';
import Sidebar from "../../components/Sidebar/Sidebar";
import MerchantScoreCard from "../../components/DashboardComponents/MerchantScoreCard";
import CampaignStatusTable from "../../components/DashboardComponents/CampaignStatusTable";
import {ServiceWorker} from "../../services/ServiceWorker";
import PayoutTableComponent from "../../components/DashboardComponents/PayoutTableComponent";

const containerStyle = {
  width: 'inherit',
  height: '70%'
}


class DashboardPage extends React.Component {
  constructor(props) {
   super(props);
   this.state ={
     isLoading:true,
     current:1,
     ready:false,
     showTable:true,
     payoutDataFull:[],
     payoutData:[]
    }

    this.changeTab = this.changeTab.bind(this);
    this.loaded = this.loaded.bind(this);
    this.togglePayoutTable = this.togglePayoutTable.bind(this);

  }
async componentDidMount() {

  let status = await ServiceWorker.isLoggedIn();
        if(status==false){
            this.props.history.push('/login');
        }
  let response = await ServiceWorker.getPayoutData();
  console.log("response ",response)
  this.setState({ 
    payoutData:response.vouchersToShowTemp,
    payoutDataFull:response.full,
  })

}

changeTab(num){
  this.setState({current:num})
}

loaded(){
  this.setState({ready:true})
}

togglePayoutTable(){
  this.setState({showTable:!this.state.showTable})
}

render(){

  return(
    <div className='dashboard-main-body'>
      {this.state.ready==false?
                <div style={{ position : "fixed", backgroundColor: "white", flex:1, width: "100%", height:"100%", zIndex: 999}}>
                <div className="loader-blank"></div>
                </div>:null}
      <div class="drawer-body-dashboard" style={{ width: "14%" }}>
         <Sidebar nav={this.props.history} activePage="dashboard"/>
      </div>

      <div className='dashboard-left-body'>
      <div className="merchant-option-buttons">
          {/* <DashboardScoreComponent isReadyDashboardScores={this.isReadyDashboard.bind(this)}/>     */}
          <button onClick={(e)=>this.changeTab(1)} className={this.state.current==1?"when-active":"when-not-active"}>Merchant</button>
          <button onClick={(e)=>this.changeTab(2)} className={this.state.current==2?"when-active":"when-not-active"}>Subscriber</button>
          <button onClick={(e)=>this.changeTab(3)} className={this.state.current==3?"when-active":"when-not-active"}>Mobile User</button>
      </div>
      <hr style={{ width:"100%", height: 1 }} />

      <div className='statRow'>
        <MerchantScoreCard loaded={()=>this.loaded()} />
      </div>  
      <div className='payout-section'>
        <button className='common-button' onClick={(e)=>this.togglePayoutTable(e)}>
          Show Pending Payouts
        </button>
      </div> 
      <div className='payout-table'>
        <PayoutTableComponent payoutData={this.state.payoutData} payoutDataFull={this.state.payoutDataFull}/>
      </div>
        
      </div>
    </div>
  )

}}
//export default ;
//Dashboard.defaultProps = MapStyle;

export default DashboardPage;