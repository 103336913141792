// Do not add forward slash / after the end of any URL.
// export const API_URL=process.env.REACT_APP_STRAPI_BACKEND_URL;
export const API_URL = process.env.REACT_APP_STRAPI_BACKEND_URL
export const CMS_URL = process.env.REACT_APP_CMS_URL
export const CC_URL = process.env.REACT_APP_CUSTOMER_PORTAL_URL
export const primaryColor = "#2A2A5F";
export const secondaryColor = "#00B47F";
export const secondaryColor1 = "rgb(48, 216, 162)";
export const greyColor = "#3d4160";
export const whiteColor = "#fff";
export const DEV_PYTHON_SERVICE_URL = process.env.REACT_APP_VIDEO_SERVICE_URL
export const RECAPTCHA_KEY ="6Lc8luEZAAAAAEGo9AaOurE0IZ6qUEpGTCKG-zD4"
export const GEO_KEY = process.env.REACT_APP_GEO_KY
export const CAMPAIGN_NAME_LIMIT = 100
export const TEXT_LIMIT = 100
export const DEFAULT_DEAL_FORM = "<p><strong>Product Details</strong></p> <p>&lt;Provide product details here&gt;</p> <p><br></p> <p><strong>Terms &amp; conditions</strong></p> <p><strong>&lt; </strong>fine prints<strong> &gt;</strong></p>";
export const DECIMALS_ALLOWED = 2
export const NUMBER_LIMIT = 99999.99
export const PASSWORD_ERROR_MSG = "*Password must be at least 8 characters long and should contain a number, a capital letter and a special character."
export const VERSION_NO = '1.7.1'
export const commonButtonColor = '#4e5ff9'
export const commonRedButtonColor = '#F94E4E'
export const disabledButton = '#7D89F0'
export const campaignTitleExample = "e.g. 10% off on the first piano lesson OR Window tinting with life time warranty"
export const limitedQuantityTitleExample = "Be descriptive. e.g., 5 online music lesson of 30 mins"
export const limitedTimeTitleExample = "Be descriptive. e.g., 2 FREE sessions with 4 hair removal sessions"
export const percentageDiscountTitleExample = "Be descriptive. e.g., 20% off on 3 months online lessons when paid in advance"
export const EndsOnTitle = "Be descriptive. e.g., Get $15 voucher on first round of golf "
export const NoDealTitleExample = "Be descriptive. e.g., Math lessons Mon, Wed, Fri for a month";
export const customStyles = {
    content : {
      top                   : '45%',
      left                  : '58%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      height                 : 'auto',
      width                 :'65%',
      minHeight             :"162px",

    }
  };
export const mobileLaunchText="The mobile listing is FREE. However, there is a 3%+30 cent card transaction charge when users purchase items using a card, Apple Pay or Google Pay.";
export const mobileLaunchError="*Kindly make sure the checkbox is selected."
export const SCH_URL = process.env.REACT_APP_SCH_SERVICE_URL

// Adding commnet to check merge3 conflict on ci cd.
