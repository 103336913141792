import React from 'react';
import Sidebar from "../../components/Sidebar/Sidebar";
import "./UserPage-style.css";
import TicketTimeline from "../../components/TicketsComponent/TicketTimeLine";
import Timeline from "../../components/TimeLine/TimeLine";
import UserRefundTable from "../../components/UserRefundTableComponent/UserRefundTable";
import { ServiceWorker } from "../../services/ServiceWorker";


class UserPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            campaignButtonActive: true,
            mobileUser:null,
            mobileUserID:null
        };
        this.setUser = this.setUser.bind(this);
        this.toggleHistorySection = this.toggleHistorySection.bind(this);
        this.setMobileUser = this.setMobileUser.bind(this);
    }

    async componentDidMount() {

    }

    toggleHistorySection() {
        this.setState({
            campaignButtonActive: !this.state.campaignButtonActive
        })
    }

    async setMobileUser(){
        let userID = await ServiceWorker.getUserID(this.state.mobileUser);
        console.log('user id=',userID)
        this.setState({
            mobileUserID:userID
        })
    }

    setUser(e){
        console.log("e.target.value =" ,e.target.value)
        this.setState({
            mobileUser:e.target.value
        })
    }



    render() {
        return (
            <div className="userPage-page-main">
                <div className="userPage-sidebar">
                    <Sidebar nav={this.props.history} activePage={'userPage'} />
                </div>
                <div className="userPage-right-body">
                    <div className="userPage-right-input-bar">
                        <input
                            type="search"
                            placeholder="Search user Ph/ID/Email ."
                            onChange={(e)=>this.setUser(e)}
                        />
                        <button className="common-button"
                        onClick={this.setMobileUser}
                        >submit</button>
                    </div>


                    <hr style={{ width: "100%", height: 1 }} />

                    <div className='userPage-below-section'>
                        <div className='ticket-section'>
                            <div className='open-tickets-section'>
                                <h4>Open Tickets</h4>
                                <TicketTimeline />
                            </div>
                            <hr style={{ width: "100%", height: 1 }} />

                            <div className='closed-tickets-section'>
                                <h4>Closed Tickets</h4>

                                <TicketTimeline />

                            </div>
                        </div>

                        <div className='userPage-other-options'>
                                <button>Create Ticket</button>

                                <button>Apply Refund</button>

                                <button>Cancel Order</button>
                        </div>

                        <div className='user-timeline-section'>
                            <h4>User Activity Timeline</h4>
                            <Timeline />
                        </div>

                        
                    </div>
                    <div className='user-refund-table'>
                    <hr style={{ width: "100%", height: 1 }} />
                    <h4>Refund Section</h4>
                        <UserRefundTable mobileUserID={this.state.mobileUserID} 
                            mobileUserEmail={this.state.mobileUser}
                        />
                    </div>


                </div>
            </div>
        )
    }

}

export default UserPage;
