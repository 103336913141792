import {CAMPAIGN_NAME_LIMIT,PASSWORD_ERROR_MSG} from './config';


export class GenericValidators{

    // Only 100 char limit.
    static isValidTextField(data,charLimit){
        if(data.trim().length!=0)
        {   data = data.trim()
            console.log("Validating: ",data);
            let check= data.split('').length>CAMPAIGN_NAME_LIMIT-1?`Deal title cannot exceed more than ${CAMPAIGN_NAME_LIMIT} characters.`:null;
            if(check!=null)
            {
                return {"status":false,"message":check}
            }
            return {"status":true,"message":''}
        }else{
            return {"status":false,"message":'please enter a valid input'}
        }
    }

    // 
    static async isValidNumberField(data,numberLimit,numberOfDecimalsAllowed)
    {if(data.trim().length!=0)
    {   data = data.trim()
        console.log("data,limit,decimalsAllowed=",data,numberLimit,numberOfDecimalsAllowed);
        //check if it is a valid number and less than or equal to numberLimit.
        if((!isNaN( Number(data)) && (Number(data)<=0)) )
        {
            return {"status":false,'message':`Invalid input`}
        }
        else if(numberOfDecimalsAllowed>0)
        {   console.log("entering")
            if((!isNaN( Number(data)) && (Number(data)<=numberLimit)) == false)
            {   console.log("returning")
                return {"status":false,'message':`Only ${numberOfDecimalsAllowed} decimal numbers upto ${numberLimit} are permitted`}
            }
            else if((!isNaN( Number(data)) && (Number(data)<=numberLimit)) == true && 
            data.split('.')[1]!=undefined && data.split('.')[1].length>2)
            {   console.log("yo=",data.split('.'))
                return {"status":false,'message':`Only ${numberOfDecimalsAllowed} decimal numbers upto ${numberLimit} are permitted`}
            }
            
        }else{
            if((!isNaN( Number(data)) && (Number(data)-Math.floor(Number(data))===0) )==false)
            {
                return {"status":false,"message":`Only numbers upto ${numberLimit} are permitted`}
            }
            if(!isNaN( Number(data)) && (Number(data)<=numberLimit)== false)
            {
                return {"status":false,"message":`Only numbers upto ${numberLimit} are permitted`}
            }
            if(!isNaN( Number(data)) && (Number(data)<=numberLimit)== false )
            {
                return {"status":false,"message":`Only numbers upto ${numberLimit} are permitted`}
            }
        }
        return {"status":true,'message':''}
        }
        return {"status":true,'message':'Please enter a valid input'}
        // check if number of decimal are allowed in number.
    }

    static isPasswordValid(pwd)
    {    console.log("pwd",pwd)
        if (!pwd.match(/^.*(?=.{8,})((?=.*['!~@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/)) {
            
            return {'status':false,'message':PASSWORD_ERROR_MSG};
          }
        else{
            return {'status':true,'message':'true'};
        }
    }


    static isEmailValid(email)
    {
        console.log("checking:",email);
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(email)) {
          return false;
        }
        else{
            return true;
        }
    }

}