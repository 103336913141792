import React from 'react';
import Griddle, { plugins, RowDefinition, ColumnDefinition } from 'griddle-react';
import { ServiceWorker } from "../../services/ServiceWorker";
import { Table } from 'rsuite';
import {CMS_URL} from '../../common/config'
import moment from 'moment';
import ReactModal from 'react-modal';
import "./PayoutTable-styles.css";

const customStyles = {
    content : {
      top                   : '45%',
      left                  : '58%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      maxHeight             :'518px',
      width                 :'75%',
      minHeight             :"518px",
    }
  };

class PayoutTableFullViewComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            merchantID:props.location.pathname.split("/")[2]
        }
      
    }

    async componentDidMount() {
        this.renderData();
    }

    async renderData() {
        let response = await ServiceWorker.getPayoutData();
        let data = response.full;
        let merchant = this.state.merchantID
        let tempFull1=data.filter(function (elem){
            if(elem.merchantUserID == merchant){
                return elem;
            }
        })
        tempFull1.map(elem=>{
            elem.stopPayment=elem.id
        })
        console.log("Merchant =",merchant,tempFull1)
        this.setState({
            data: tempFull1,
        });
        if(tempFull1.length>0){
            this.setState({
                merchantEmail: tempFull1[0].email,
                merchantStripePayoutID: tempFull1[0].payoutID
            });
        }
    }


    // componentDidUpdate(prevProps,prepState) {
    //     // Typical usage (don't forget to compare props):
    //     if ((this.props.payoutDataFUll !== prevProps.payoutDataFUll)) {
    //         this.renderData();
    //     }
    // }


  
    async viewMerchant(merchant){
        var merchantID = merchant
        console.log(merchantID)
        let tempFull = this.state.detailedPayout;
        let tempFull1=tempFull.filter(function (elem){
            if(elem.merchantUserID == merchant){
                return elem;
            }
        })
        await this.setState({
            tempDetailed:tempFull1,
            showModal:true
        },()=>console.log(this.state.showModal))
        
    }

    async closeModal(){
        await this.setState({
            showModal:false
        })   
    }

    async payAmount(elem){
        console.log(elem)
    }

    async stopPayment(voucher){
        let voucherID = [voucher]
        console.log("voucherIDs=",voucherID)
        let payoutResponse = await ServiceWorker.stopPayment(voucherID);
        console.log(payoutResponse)
        if(payoutResponse.status==true){
            window.location.reload(true);
        }
    }





    render() {
        const { loading, displayLength, page, } = this.state;
        const CustomHeading = ({ title }) => <span style={{ color: 'black' }}>{title}</span>;
        const CustomColumn = ({ value }) => <span style={{ textAlign: 'left' }}>{value}</span>;
        // const customActivate = ({value}) => <a 
        // onClick={(e)=>this.activate(value)} >
        // <button style={{cursor:'pointer' }}>Activate</button></a>
        const sendMail = ({ value }) => <a
            onClick={(e) => this.activate(value)} >
            <button style={{ cursor: 'pointer' }}>Send alert</button></a>

        console.log('render sectoin campaignOpened',this.state.campaignOpened)
       
        const customActivateDisabled = ({ value }) => <a
        key={value}
        onClick={(e) => alert('please review campaign using view first.')} >
        <button style={{ cursor: 'pointer' }}>Disabled</button></a>
    

        const visitCampaign = ({ value }) => <a
            onClick={(e) => this.viewMerchant(value)} >
            <button style={{ cursor: 'pointer',float:'inherit' }}>view details</button></a>
       
       const pay = ({ value }) => <a
        onClick={(e) => this.payAmount(value)} >
        <button style={{ cursor: 'pointer' ,float:'inherit'}}>Pay $</button></a>

        const stop = ({ value }) => <a
        onClick={(e) => this.stopPayment(value)} >
        <button style={{ cursor: 'pointer' ,float:'inherit'}}>Stop Payment</button></a>


        return (
            <div className='purchase-history-body' style={{ display: "flex", background: "white" }}>
                <div className='purchase-history-table' style={{ width: "100%" }}>
                    <h3>Pending Payouts for Merchant: {this.state.merchantID}</h3>
                    <h4>Email: {this.state.merchantEmail}</h4>
                    <h4>payoutID: {this.state.merchantStripePayoutID}</h4>
                   <div className="payout-table"> 
                   
                   <Griddle
                        data={this.state.data}
                        plugins={[plugins.LocalPlugin]}
                    >
                        <RowDefinition >
                        <ColumnDefinition id="updatedAt" title="Updated At" customHeadingComponent={CustomHeading} />                            
                        <ColumnDefinition id="id" title="VoucherID" customHeadingComponent={CustomHeading} /> 
                        <ColumnDefinition id="campaignID" title="CampaignID" customHeadingComponent={CustomHeading} /> 
                        <ColumnDefinition id="optionID" title="OptionID" customHeadingComponent={CustomHeading} /> 
                        <ColumnDefinition id="cost" title="Cost $" customHeadingComponent={CustomHeading} /> 

                            {/* <ColumnDefinition id="merchantUserID" title="Merchant ID" customHeadingComponent={CustomHeading} customComponent={CustomColumn} /> */}
                            {console.log("this.state.campaignOpened-> ",this.state.campaignOpened)}   
                        
                            {/* <ColumnDefinition id="payoutID" title="Stripe Account" customHeadingComponent={CustomHeading} />  */}
                            {/* <ColumnDefinition id="redeemID" title="redeemID" customHeadingComponent={CustomHeading} />  */}
                            <ColumnDefinition id="stopPayment" title="Stop Payment" customHeadingComponent={CustomHeading} customComponent={stop} />

                            {/* <ColumnDefinition id="tempMerchant" title="View Complete Payments" customHeadingComponent={CustomHeading} customComponent={visitCampaign} /> */}
                            {/* <ColumnDefinition id="id" title="Action" customHeadingComponent={CustomHeading} customComponent={this.state.campaignOpened===true?customActivate:customActivateDisabled} /> */}
                            
                        </RowDefinition>
                    </Griddle>
                   </div>
        
                </div>
            </div>
        )
    }


}
export default PayoutTableFullViewComponent;