import React from 'react';
import Sidebar from "../../components/Sidebar/Sidebar";
import "./MerchantPage-style.css";
import MerchantCampaignSection from "../../components/MerchantCampaignSection/MerchantCampaignSection";
import MerchantCampaignHistory from "../../components/MerchantCampaignSection/MerchantCampaignHistory";
import {CMS_URL} from '../../common/config'
import {ServiceWorker} from '../../services/ServiceWorker';

class MerchantPage extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            campaignButtonActive:'campaign',
            merchantQueryInput:'',
            pubID:'',
            merchant:null,
            ready:false
        };

        this.toggleHistorySection = this.toggleHistorySection.bind(this);
        this.queryMerchant = this.queryMerchant.bind(this);
        this.triggerUserSearch = this.triggerUserSearch.bind(this);
    }

    async componentDidMount(){
        let status = await ServiceWorker.isLoggedIn();
        if(status==false){
            this.props.history.push('/login');

        }else{
        let pubID = await localStorage.getItem('publisherID');
        let clientEmail= await localStorage.getItem('clientEmail');
        this.setState({
            merchant:pubID,
            clientEmail:clientEmail
        })
        }
        this.setState({
            ready:true
        })

        
    }

 
    toggleHistorySection(tabName){
        console.log('clicked')
        this.setState({
            campaignButtonActive:tabName
        })
    }

    queryMerchant(e){
        try{
            e.preventDefault();
        }catch(e){
            console.log(e)
        }
        console.log("ip-> ",e.target.name,e.target.value);
        this.setState({
            [e.target.name]: e.target.value,
        })
        
    }

    async triggerUserSearch(e){
        e.preventDefault();
        console.log("search for=> ",this.state.merchantQueryInput);
        let response = await ServiceWorker.loginClient(this.state.merchantQueryInput,false,false);
        try{
            console.log('response',response[0].data.publisherID);
            await this.setState({
                pubID:response[0].data.publisherID
            })
            if(response!=false){
                await localStorage.setItem("clientEmail",this.state.merchantQueryInput);
            }
            window.location.reload()

        }catch(e){
            console.log(e);
        }

    }

    async clearMerchant(){
        //await localStorage.setItem('publisherID', undefined);
        localStorage.removeItem('publisherID');
        localStorage.removeItem('clientEmail');
        localStorage.removeItem('clientUserLogin');
        window.location.reload()

    }

    render() {
        return (
            <div className="merchant-page-main">
                 {this.state.ready==false?
                <div style={{ position : "fixed", backgroundColor: "white", flex:1, width: "100%", height:"100%", zIndex: 999}}>
                <div className="loader-blank"></div>
                </div>:null}
               <div className="merchant-sidebar">
                    <Sidebar nav={this.props.history} activePage={'merchant'}/>
               </div>
               <div className="merchant-right-body">
                   <div className="merchant-right-input-bar">
                       {this.state.merchant!=null?null:
                        <input 
                            type="search"
                            placeholder="Search Merchant Phone no. / Email ID"
                            name='merchantQueryInput'
                            onChange={this.queryMerchant}
                        />
                        }
                    {this.state.merchant!=null?<a className='loggedin-text'>Showing data of merchant: {this.state.clientEmail} </a>:null} 

                    {this.state.merchant!=null?null:   <button style={{height:'39px',margin:"1%"}} onClick={(e)=>this.triggerUserSearch(e)}>Search</button>}
                    {this.state.merchant!=null?<button
                       onClick={this.clearMerchant}
                        style={{height:'53%',margin:'1%'}}>clear</button>:null}
                 
                   </div>
                        {console.log("this.state.pubID=>",this.state.pubID)}
                   <div className="merchant-option-buttons" style={this.state.merchant==null || this.state.merchant==''?{'display':'none'}:{'color':'black'}}>
                       <button onClick={(e)=>this.toggleHistorySection('campaign')} className={this.state.campaignButtonActive=='campaign'?"when-active":"when-not-active"} >Campaigns</button>

                       <button onClick={(e)=>this.toggleHistorySection('history')} className={this.state.campaignButtonActive=='history'?"when-active":"when-not-active"}
                       disabled={this.state.merchant==null?true:false}
                       >History</button>


                       

                   </div>
                   <hr style={{ width:"100%", height: 1 }} />

                   <div className="merchant-below-buttons-items">
                       {console.log("this.state.pubID=",this.state.pubID)}
                        {this.state.campaignButtonActive=='campaign'?<MerchantCampaignSection pubID={this.state.pubID}/>:<MerchantCampaignHistory pubID={this.state.pubID}/>}
                   </div>


               </div>
            </div>
        )
    }

}

export default MerchantPage;
