import React, { Component } from 'react'
import "./Subscriber-page-style.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import {ServiceWorker} from "../../services/ServiceWorker";
import FloatingTextManagerComponent from '../../components/FloatingTextManagerComponent/FloatingTextManagerComponent';
import DeviceManagement from '../../components/DeviceManagement/DeviceManagement';

export default class SbuscriberPage extends Component {
    constructor(props) {
        super(props);
        this.state ={
            current:1,

         }
     
       }
     async componentDidMount() {
     
       
     
     }
     changeTab(num){
        this.setState({current:num})
    }
    
    componentToShow(selected){
      console.log("hello");
      console.log(selected);
      switch(selected){
        case 1:
          return <DeviceManagement />;
          break;
        case 2:
          return <FloatingTextManagerComponent />
          break;
        case 3:
          return <h1>Dashboard devices</h1>;
          break;
        default:
          return <FloatingTextManagerComponent />
          break;
      }
    }

    render() {
    
        return(
            <div className='dashboard-main-body'>
              {this.state.ready==false?
                        <div style={{ position : "fixed", backgroundColor: "white", flex:1, width: "100%", height:"100%", zIndex: 999}}>
                        <div className="loader-blank"></div>
                        </div>:null}
              <div class="drawer-body-dashboard" style={{ width: "14%" }}>
                 <Sidebar nav={this.props.history} activePage="display"/>
              </div>
        
              <div className='dashboard-left-body'>
              <div className="merchant-option-buttons">
                  {/* <DashboardScoreComponent isReadyDashboardScores={this.isReadyDashboard.bind(this)}/>     */}
                  <button onClick={(e)=>this.changeTab(1)} className={this.state.current==1?"when-active":"when-not-active"}>Devices</button>
                  <button onClick={(e)=>this.changeTab(2)} className={this.state.current==2?"when-active":"when-not-active"}>Floating Banner Text</button>
                  <button onClick={(e)=>this.changeTab(3)} className={this.state.current==3?"when-active":"when-not-active"}>Device Dashboard</button>
              </div>
              <hr style={{ width:"100%", height: 1 }} />
                {this.componentToShow(this.state.current)}
                
              </div>
            </div>
          )
        
    }
}
