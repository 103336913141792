import React from 'react';
import "./FilterOptions-style.css";
import { IconContext } from "react-icons";
import { FaSortNumericUpAlt,FaSortNumericDown } from "react-icons/fa";

class FilterOptions extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            sortDateDescending:true,
            pendingApproval:false,
            draft:false,
            active:false,
            deleted:false,
        };

        this.sortByDate = this.sortByDate.bind(this);

    }

    async componentDidMount(){

    }

    async sortByDate(){
        this.setState({
            sortDateDescending:!this.state.sortDateDescending
        },()=>this.props.reverseCampaign())

    }

    async filterApply(e){
        console.log(e)
        await this.setState({
            [e]:!this.state[e]
        },()=>{this.props.filterCampaign({'pendingApproval':this.state.pendingApproval,'draft':this.state.draft,
        'active':this.state.active,'deleted':this.state.deleted})})
    }
    render() {
        return (
            <div className="filter-page-main">
               <div className="filter-input">

                    <div className="date-filter">
            
                    <div className="text-drawer"  >
                    <a >Date:</a>
                    </div>
                    
                    <div className="date-filter-icons" onClick={this.sortByDate}>
                        <IconContext.Provider value={{ color: "black",size:"1.35em", className: "global-class-name" }}>
                        {this.state.sortDateDescending?<FaSortNumericDown />:<FaSortNumericUpAlt/>}
                        </IconContext.Provider>
                    </div>

                    <div className="option-filter-section"> 
                        <a>Review: </a><input type='checkbox'  name="inReview"
                        onChange={(e)=>this.filterApply('pendingApproval')}
                        /> 
                        <a>Draft: </a><input type='checkbox' name="inDraft"
                        onChange={(e)=>this.filterApply('draft')}
                        /> 
                        <a>Active: </a><input type='checkbox'
                        onChange={(e)=>this.filterApply('active')}
                        name="active"/> 
                        <a>Deleted: </a><input type='checkbox'
                        onChange={(e)=>this.filterApply('deleted')}
                        name="deleted"/>
                    </div>
                    </div>

                   
               </div>
            </div>
        )
    }

}

export default FilterOptions;
