import React from 'react';

import "./Sidebar-style.css"
import { FaUserCircle, FaHome, FaPollH, FaPhotoVideo, FaPowerOff, FaUserEdit, FaTv } from "react-icons/fa";
import { IconContext } from "react-icons";
import userIcon from '../../assets/userIcon.png';
import { AiOutlineFundProjectionScreen } from "react-icons/ai";


class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignList: [],
      name: 'John Doe'
    }
  }

  componentDidMount() {
    this.checkUserType();
  }

  async checkUserType() {
    let name = await localStorage.getItem('name');
    let phone = await localStorage.getItem('user');
    name != null && name.trim().length > 0 ? console.log("name available= ", name) : name = phone;
    await this.setState({
      name
    });
  }

  async logout() {
    localStorage.clear();
    localStorage.setItem('showLogin', '1');
    this.props.nav.push("/login")
  }

  render() {
    // const role=this.state.role;
    return (
      <div className="sidebar">
        <div style={{ textAlign: "center", position: 'absolute', bottom: 5, marginLeft: '30%' }} className="version-text">
          <div className="vu-logo">
            {/* <img src={appIconW} style={{width:"40px"}} alt=">"/> */}
          </div>
          {/* <a >Version: {VERSION_NO}</a> */}
        </div>
        <div>


          <div className="sidebar-header">
            <div className="user-pic">
              <img className="img-responsive img-rounded" src={userIcon} alt="User picture" />
            </div>
            <div className="user-info">
              <span className="user-name">
                <p>{this.state.name}</p>
              </span>

            </div>
          </div>

          <div className="sidebar-content">

            <div className={"advertiser-part"}>
              {/* <div style={{ marginTop: 15, marginBottom: -15, paddingLeft: "20%" }}>
            <p style={{ color: "white", fontSize: 15 }}>Merchant</p>
            </div> */}

              <div className={"label-text-drawer"} style={this.props.activePage == "dashboard" ? { backgroundColor: "#393B4C" } : null} onClick={() => this.props.nav.push("/dashboard")}>

                <div className="text-drawer">
                  <a >Dashboard</a>
                </div>

                <div className="icon-arrow">
                  <IconContext.Provider value={{ color: "#ffffffbe", size: "1.35em", className: "global-class-name" }}>
                    <FaHome />
                  </IconContext.Provider>

                </div>

              </div>
              <div className={"label-text-drawer"} style={this.props.activePage == "merchant" ? { backgroundColor: "#393B4C" } : null} onClick={() => this.props.nav.push("/merchant")}>

                <div className="text-drawer">
                  <a >Merchant</a>
                </div>

                <div className="icon-arrow">
                  <IconContext.Provider value={{ color: "#ffffffbe", size: "1.35em", className: "global-class-name" }}>
                    <FaPollH />
                  </IconContext.Provider>

                </div>

              </div>

              <div className={"label-text-drawer"} style={this.props.activePage == "display" ? { backgroundColor: "#393B4C" } : null}>

                <div className="text-drawer" onClick={() => this.props.nav.push("/display")} >
                  <a >Subscriber</a>
                </div>

                <div className="icon-arrow">
                  <IconContext.Provider value={{ color: "#ffffffbe", size: "1.35em", className: "global-class-name" }}>
                    <AiOutlineFundProjectionScreen />
                  </IconContext.Provider>
                </div>

              </div>



              <div className={"label-text-drawer"} style={this.props.activePage == "userPage" ? { backgroundColor: "#393B4C" } : null}>

                <div className="text-drawer" onClick={() => this.props.nav.push("/user")} >
                  <a >Mobile </a>
                </div>
                <div className="icon-arrow">
                  <IconContext.Provider value={{ color: "#ffffffbe", size: "1.35em", className: "global-class-name" }}>
                    <FaUserEdit />
                  </IconContext.Provider>
                </div>
              </div>





              <div className="label-text-drawer" onClick={() => this.logout()}>

                <div className="text-drawer">
                  <a >Logout</a>
                </div>

                <div className="icon-arrow">
                  <IconContext.Provider value={{ color: "#ffffffbe", size: "1.35em", className: "global-class-name" }}>
                    <FaPowerOff />
                  </IconContext.Provider>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

    );
  }
}

export default Sidebar;
