import React from 'react';

import {commonButtonColor, whiteColor,RECAPTCHA_KEY,PASSWORD_ERROR_MSG} from '../../common/config';
import {GenericValidators} from '../../common/GenericValidators';
import { ServiceWorker } from '../../services/ServiceWorker';
import appIcon from '../../assets/VuClink_Logo_square.png';
import './loginStyle.css';

import Recaptcha from 'react-google-invisible-recaptcha';
import { FaEye,FaEyeSlash } from "react-icons/fa";
import { IconContext } from "react-icons";


class Login extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          username: '',
          password: '',
          isLoading: false,
          showLoginPage:true,
          showRegisteredFirstTime:false,
          captchaPassed:false,
          showPassError:false,
          showPasswordIcon1:true,
          hidePassword:false
        };

        this.myChangeHandler = this.myChangeHandler.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onResolved = this.onResolved.bind( this );
        this.handelLogin = this.handelLogin.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.toggleForgetPassword = this.toggleForgetPassword.bind(this);
    
      }

      async componentDidMount() {
        // localStorage.clear();
        
        localStorage.clear();
      }
     async myChangeHandler(event) {
        
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if(name=='password')
        {
          if(!GenericValidators.isPasswordValid(value).status)
          {
            await this.setState({
              showPassError:true
            })
          }else{
            await this.setState({
              showPassError:false
            })
          }
        }
        console.log("p",this.state.showPassError)
        console.log(value,name)
        this.setState({
          [name]: value
        });
    
      }

      togglePassIcon(name)
      {
        this.setState({
          [name]:!this.state.showPasswordIcon1,
      })
        }
        
  async handleOnChange(value)
    {
    //  this.setState({ phone: value })
    console.log("username= ",value)
      this.setState({
        username:value,
      });
      console.log(this.state.username,this.state.username.length);
    }

    async clearCurrentUser() {
      alert("Something went wrong")
      this.setState({
        isLoading:false
      });
      await localStorage.clear();
      await this.props.resetUser("");
    }

    async handleSubmit(event) {
      this.setState({isLoading:true})
        event.preventDefault();
        await this.recaptcha.reset();
        await this.recaptcha.execute();
        console.log("--resolved--")
      }

    checkAccess(userRole) {
      console.log("userRole",userRole);
        if((userRole=="support" || userRole=="admin" )){
          return true;
        } else {
           return false;
        }
    }

    async checkRoute(userRole, advID) {

        switch(userRole){
          case "admin":
            this.props.history.push('/device');
            break;
          
          case "support":
            this.props.history.push('/campaign');
            break;
          default:
            this.props.history.push('/');
            break;

        }
          
    }
        

    async handelLogin()
    {

      this.setState({
        isLoading: true
      });
      if(this.state.showPassError===false){
     
     const userDetails = await ServiceWorker.loginUser(this.state.username,this.state.password);
      console.log(userDetails)

      if(userDetails[0]==='200') {
        const userRole = userDetails[1].user.role.name.toLowerCase();
        console.log("userRole",userRole);
          if(this.checkAccess(userRole)==false){
            alert("You are not authorized to login")
            this.setState({
              isLoading: false
            });
            return; 
          }
         let userLogin = userDetails[1]['jwt'];
         console.log('user login= ', userLogin)
          await localStorage.setItem('userLogin', userLogin);
          await localStorage.setItem('user',userDetails[1]['user'].username) //(remove)
          let firstName = userDetails[1]['user'].hasOwnProperty('firstName')?userDetails[1]['user'].firstName:'';
          let lastName = userDetails[1]['user'].hasOwnProperty('lastName')?userDetails[1]['user'].lastName:'';
          firstName=firstName.charAt(0).toUpperCase()+firstName.slice(1);
          lastName=lastName.charAt(0).toUpperCase()+lastName.slice(1);
          await localStorage.setItem('name', firstName+' '+lastName);
         // await this.props.updateToken(userDetails[1]['jwt']);

          //logic to see userType and suscriber details
          let userid = userDetails[1]['user']['id']
          let advID = null; //(remove)
          // const subsID = await ServiceWorker.getSubscriberId(userid, userDetails[1]['jwt']);
          if(userRole=="support"){
            advID = await ServiceWorker.getPublisherId(userid, userDetails[1]['jwt']); //(remove)
           // await this.props.updateUserType("merchant");
            await localStorage.setItem('userType', "support");
           // await localStorage.setItem('publisherID', advID[1]); //(remove)
            await localStorage.setItem('SupportUserID', userid);
          } else if(userRole=="admin"){
           // await this.props.updateUserType("subscriber");
            await localStorage.setItem('userType', "admin");
            await localStorage.setItem('AdminUserID', userid);
          } else {
            this.clearCurrentUser();
            return;
          }

          this.checkRoute(userRole,advID); //(remove advID paramter )

      }
      else if(userDetails[0]==='400'){
          alert("Your credentials do not match.")
          this.setState({
            isLoading: false
          });
        }
      else{
          alert("Something went wrong.")
          this.setState({
            isLoading: false
          });
        }
      }else{
        console.log("This is not a api request.")
        alert("Your credentials do not match.")
        this.setState({
          isLoading: false
        });
      }
      
    }
      toggleForm(){
        this.setState({
          showLoginPage:!this.state.showLoginPage,
        });
      }

      onChange(value) {
        console.log("Captcha value:", value)
        let ishuman = ServiceWorker.captchaVerification(value);
        if(ishuman===0)
        {
          alert("You are blocked.");
        }else{
            this.setState({
              captchaPassed:true
            })
        }
      }

      onResolved() {
        console.log('reached')
        // alert( 'Recaptcha resolved with response: ' + this.recaptcha.getResponse() );
        this.handelLogin();
      }

      toggleForgetPassword(e){
        console.log('toggleForgetPassword=',e);
        let hideStatus= this.state.hidePassword;
        this.setState({
          hidePassword:!hideStatus
        });
      }

    

    render() {
      return (
        <div className="login-wraper" >
                  <a style={{cursor: "pointer",alignSelf:"flex-end",marginRight:"5%"}}> {this.state.showLoginPage?<a style={{fontWeight:"bold"}} onClick={(e)=>this.toggleForm(e)}><a style={{color:commonButtonColor}}> </a> </a>:<a style={{fontWeight:"bold"}} onClick={(e)=>this.toggleForm(e)}><a style={{color:commonButtonColor}}>Login </a></a>} </a>
          <div className="login-form-container">

               <div className="login-form-inner">
               <img src={appIcon} alt="vuclink"/>  
               </div>
                {this.state.showLoginPage?
               <div className="login-form-sec">
              <form onSubmit={this.handleSubmit}>
                <label>Username</label>
                <div className="phone-number-div">
                <input
                  type={"text"}
                  name='username'
                  autoComplete="new-password"
                  maxLength="50"
                  onChange={this.myChangeHandler}
                />
                </div>
                {this.state.showPhoneError?<div className="_9ay7">*Phone number is not valid.</div>:""}
                {this.state.hidePassword===false?
                <div>
                <label>Password</label>
                <div style={{display:"flex"}}>
                <input
                  type={this.state.showPasswordIcon1?"password":"text"}
                  name='password'
                  autoComplete="new-password"
                  maxLength="50"
                  onChange={this.myChangeHandler}
                />
                <div style={{marginTop:"18px",marginLeft:"-45px"}} onClick={(e)=>this.togglePassIcon('showPasswordIcon1')}>
                  <IconContext.Provider value={{ color: "#636f83;",size:"1.4em", className: "upper-icons-three" }}>
                    {this.state.showPasswordIcon1?<FaEyeSlash />:<FaEye/>} 
                  </IconContext.Provider>
                </div>
                </div>
                  {this.state.isLoading?<div class="loader"></div>:<input type="submit" value="Log In" style={{ backgroundColor: commonButtonColor, color: whiteColor }}/>}
                  </div>:''}
                {this.state.hidePassword?<div>
                  {this.state.isLoading?<div class="loader"></div>:
                  null}

                </div>:''}

                  {/* <div className="captcha-box" style={{display:"flex",justifyContent:"center"}}><ReCAPTCHA  ref={recaptchaRef} size="invisible" sitekey={RECAPTCHA_KEY} /></div> */}
                  <Recaptcha
                    ref={ ref => this.recaptcha = ref }
                    sitekey={RECAPTCHA_KEY}
                    onResolved={ this.onResolved } />
                </form>
              
                </div>:
                  null
                }      
                
            </div>
              
          </div>
            );
      }
}


export default Login;