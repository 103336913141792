import React from 'react';
import "./score-card.css";
import MyCard from './Card';
import { ServiceWorker } from '../../services/ServiceWorker';
import CampaignStatusTable from './CampaignStatusTable';

class MerchantScoreCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            merchantPendingCreation: '0',
            merchantPendingApproval: '0',
            merchantExpthisWeek: '0',
            merchantOpenTickets: '0',
            merchantOnHoldCampaigns: '0',
            merchantOutOfQtyCampaigns: '0',
            clickedCard: 1,
            fullData: null,
            tableToShow:[],
            tableToShowName:'Pending Approval',
        }

        this.clickedCard = this.clickedCard.bind(this);
    }

    async componentDidMount() {
        let merchantdashboarddata = await ServiceWorker.getMerchantDashboardData();
        console.log('merchantdashboarddata =', merchantdashboarddata[0])
        if (merchantdashboarddata != false) {
            await this.setState({
                merchantPendingCreation: '0',
                merchantPendingApproval: merchantdashboarddata[0].pendingApproval.count,
                merchantExpthisWeek: merchantdashboarddata[0].ExpiringThisWeek.count,
                merchantOpenTickets: '0',
                merchantOnHoldCampaigns: merchantdashboarddata[0].OnHold.count,
                merchantOutOfQtyCampaigns: merchantdashboarddata[0].limitedQtyCampaigns.count,
                fullData:merchantdashboarddata[0],
            })
        }
        this.clickedCard(1)
        this.props.loaded();
    }

    clickedCard(card) {
        console.log(card)
        this.setState({
            clickedCard: card
        })
        this.dataToShow(card)
    }

    dataToShow(card){
        try{
            switch(card){
                case 1:
                    this.setState({
                        tableToShow:this.state.fullData.pendingApproval.campaigns,
                        tableToShowName:'Pending Approval'
                    }) 
                    break;
                case 2:
                    this.setState({
                        tableToShow:this.state.fullData.pendingApproval.campaigns,
                        tableToShowName:'Pending Approval'
                    }) 
                    break;
                case 3:
                    this.setState({
                        tableToShow:this.state.fullData.ExpiringThisWeek.campaigns,
                        tableToShowName:'Campaigns Expiring this week'
                    }) 
                    break;
                case 4:
                    this.setState({
                        tableToShow:this.state.fullData.ExpiringThisWeek.campaigns,
                        tableToShowName:'Campaigns Expiring this week'
                    }) 
                    break;
                case 5:
                    this.setState({
                        tableToShow:this.state.fullData.OnHold.campaigns,
                        tableToShowName:'Campaigns on Hold'
    
                    }) 
                    break;
                case 6:
                    this.setState({
                        tableToShow:this.state.fullData.limitedQtyCampaigns.campaigns,
                        tableToShowName:'Campaigns Running out of Qty.'
    
                    }) 
                    break;
                default:
                    this.setState({
                        tableToShow:this.state.fullData.ExpiringThisWeek.campaigns,
                        tableToShowName:'Campaigns Expiring this week'
    
                    }) 
                    break;
            }
        }catch(e){
            console.log(e)
        }
    }



    render() {

        return (
        <div className="dash-body">
            <div className="card-row">
                <div className="card-row-pr">
                   
                    <div className={this.state.clickedCard==1?"card-clicked":'card'}  onClick={(e)=>this.clickedCard(1)}>
                        <MyCard name={'Pending Approval'} number={this.state.merchantPendingApproval} />
                    </div>

                    <div className={this.state.clickedCard==2?"card-clicked":'card'} onClick={(e)=>this.clickedCard(2)} >
                        <MyCard name={'Pending Creation'} number={this.state.merchantPendingCreation} />
                    </div>
                    <div className={this.state.clickedCard==3?"card-clicked":'card'}  onClick={(e)=>this.clickedCard(3)}>
                        <MyCard name={'Expiring this week'} number={this.state.merchantExpthisWeek} />
                    </div>
                </div>

                <div className="card-row-se">
                    <div className={this.state.clickedCard==4?"card-clicked":'card'}  onClick={(e)=>this.clickedCard(4)}>

                        <MyCard name={'Open support ticket'} number={this.state.merchantOpenTickets} />
                    </div>
                    <div className={this.state.clickedCard==5?"card-clicked":'card'}  onClick={(e)=>this.clickedCard(5)}>

                        <MyCard name={'On Hold'} number={this.state.merchantOnHoldCampaigns} />
                    </div>
                    <div className={this.state.clickedCard==6?"card-clicked":'card'}  onClick={(e)=>this.clickedCard(6)}>

                        <MyCard name={'Running out of Qty'} number={this.state.merchantOutOfQtyCampaigns} />
                    </div>
                </div>

            </div>

                <div className='stat-table'> 
                    <CampaignStatusTable 
                        campaignData={this.state.fullData!=null?this.state.tableToShow:''} 
                        showCustomOption={this.state.clickedCard==6?true:false}
                        tableToShowName={this.state.tableToShowName}
                        number={this.state.clickedCard}
                        key={this.state.clickedCard}
                    />
                 </div>
            </div>
        )
    }


}
export default MerchantScoreCard;