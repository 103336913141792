import React from 'react';
import "./MerchantCampaign-style.css";
import FilterOptions from "../FilterOptions/FilterOptions";
import {ServiceWorker} from "../../services/ServiceWorker";
import CardComponent from "../CardComponent/CardComponent";
import Timeline from "../TimeLine/TimeLine";

class MerchantCampaignSection extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            showFilter:false,
            campaignList:[],
            pubID:null
        };

        this.toggleFilter = this.toggleFilter.bind(this);
        this.reverseCampaign = this.reverseCampaign.bind(this);
        console.log("constructor ")

    }

    componentDidMount(){
        
        this.renderCampaign();
    }

    async renderCampaign(){
       let pubID = await localStorage.getItem('publisherID');
        this.setState({
            pubID:pubID
        })
        console.log("Merchant campaign section component called .--------------------------------")
        let query = `publisherID=${pubID}`
        console.log("My query= ",query)
        const campaigns = await ServiceWorker.getCampaignByQueryParams(query);
        console.log("Here= ",campaigns);
        let localCampaigns = [];
        for(let i=0;i<campaigns.length;i++)
        {
        if(campaigns[i].contents.length>0)
        {
            localCampaigns.push(campaigns[i])
        }
        }
        localCampaigns.reverse()
        if(localCampaigns.length>0)
        {
        localCampaigns.map(item => item.isChecked=false)
        }
        this.setState({
            campaignList:localCampaigns,
            secoundCopyNotChanged:localCampaigns
        })
    } 

    // shouldComponentUpdate(prevProps,prevState){
        //
    //     if(prevState.pubID!=this.props.pubID){
    //         console.log('this.props.pubID-----',this.props.pubID)
    //         this.renderCampaign();
    //         return true;
    //     }
    //     return false;
    // }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.pubID !== prevProps.pubID) {
          this.renderCampaign();
        }
      }

    toggleFilter(){
        this.setState({
            showFilter:!this.state.showFilter
        })
    }

    filterCampaign(obj){
        console.log('obj =',obj)
        const identifiers = Object.keys(obj)
        let active = identifiers.filter(function(id) {
        return obj[id]==true;
        })
        console.log(active)
        if(active.length==0){
            active=['pendingApproval','draft','deleted','active']
        }
        let campaign = this.state.secoundCopyNotChanged;
        let campaignToSet = campaign.filter(function (elem) {
            return active.includes(elem.status);;
        })

        this.setState({campaignList: campaignToSet})
    }

    reverseCampaign(){
        let campaign = this.state.campaignList;
        campaign.reverse()
        this.setState({campaignList:campaign})

    }

    changeCampaignListOrder(idx,obj){
        let campaign = this.state.campaignList;
        campaign[idx]=obj;
        this.setState({campaignList:campaign})
        return true;
    }

    render() {
        console.log("this.props.pubID->",this.props.pubID)
        return (
            <div className="merchant-campaign-page-main">
               <div className="merchant-campaign-input">
                  {/* <input 
                        type="search"
                        placeholder="Search Campaign."
                    /> */}

                   {this.state.pubID==null?null: <button onClick={this.toggleFilter}>{this.state.showFilter?'Cancel':'Sort/Filter'}</button>}
               </div>
               {this.state.showFilter?<FilterOptions 
               reverseCampaign={(e)=>this.reverseCampaign(e)}
               filterCampaign={(e)=>this.filterCampaign(e)} />:null}
               <div className="merchant-campaign-page-contents">
                    <div className="campaign-card-section">
                        {this.state.campaignList.length==0?"No Campaign available.":null}
                        
                        {this.state.campaignList.map((item ,index)=>{
                            return(
                                <CardComponent 
                                    key={item.id}
                                    item={item}
                                    index={index}
                                    fullCampaigns={this.state.campaignList}
                                    changeCampaignListOrder={(idx,obj)=>this.changeCampaignListOrder(idx,obj)}
                                />
                            );
                        })}

                    </div>
                    <div className="merchant-right-timeline">
                        <Timeline />
                    </div>

               </div>
               
            </div>
        )
    }

}

export default MerchantCampaignSection;
