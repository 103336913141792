import React from 'react';
import './score-card.css';




class MyCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name:'',
            number:''
        }

    }

    componentDidMount(){
       

        console.log('this.props.number ',this.props.number)
        this.renderData();
    }

    renderData(){
        this.setState({
            name:this.props.name,
            number:this.props.number,
            clicked:false
        })
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.number !== prevProps.number) {
          this.renderData();
        }
      }

     


    render() {

        return( 
        <div className="" >
        <div className="card-body">
            <div className="row">
                <div className="col mt-0">
                    <h5 className="card-title">{this.state.name}</h5>
                </div>

                
            </div>
            <h1 className="mt-1 mb-3">{this.state.number}</h1>
            {/* <div className="mb-0">
                <span className="text-success"> <i className="mdi mdi-arrow-bottom-right"></i> 6.65% </span>
                <span className="text-muted">Since last week</span>
            </div> */}
        </div>
    </div>)
    }
}

export default MyCard;