import React, { Component } from 'react'
import Griddle, { plugins, RowDefinition, ColumnDefinition } from 'griddle-react';
import { ServiceWorker } from '../../services/ServiceWorker';
import { API_URL } from "../../common/config";
import { IconContext } from "react-icons";
import { FiRefreshCcw } from "react-icons/fi";
import { AiFillCloseCircle } from "react-icons/ai";
import Loader from "../Loader/Loader";
import './DeviceManagement._style.css'

import ReactModal from 'react-modal';
const customStyles = {
    content: {
        top: '45%',
        left: '58%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        maxHeight: '500px',
        width: '75%',
    }
};
export default class DeviceManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            showModal: false,
            selectedData: {},
            selectedDataFull: "-",
            editMode: 'update',
            clickedDevice: null,
            showloader: false,
            showloader1: false,
            loader3: false,
            time: 10,
            showSetting: false,
            globalVideoPercentage: 0,
            inhouseVideoPercentage: 0,
            mp4VideosPercentage: 0,
            thirdPartyVideoPercentage: 0,
            errorValue:false,
            showloader2:false
        }
        this.closeModal = this.closeModal.bind(this);
        this.loadFull = this.loadFull.bind(this);
        this.fetchInfoAndImage = this.fetchInfoAndImage.bind(this);

    }
    async componentDidMount() {
        await this.loadFull()
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    async loadFull() {
        this.setState({
            showloader: true
        })
        let device = await ServiceWorker.getDevices("active");
        console.log("device",device);
        let deviceToMarkOff=[]
        for(let i =0;i<device.length;i++) {
            // if not upadated in last 10 min then only mark it as off else do not.
            let dateToCheck = device[i].updatedAt;
            let notUpdatedInLastTenMins = await ServiceWorker.dateMoreThanTenMinutes(dateToCheck);
            if(notUpdatedInLastTenMins){
                console.log(device[i])
                console.log(device[i].status,device[i].hasOwnProperty('status'))
                if(device[i].status!=null){
                    if(device[i].status.isOn){
                        deviceToMarkOff.push(device[i].id)
                        console.log("mark ",device[i].name,device[i].id, "off")
                    }
                    
                }
                
            }
        }
        console.log("deviceToMarkOff",deviceToMarkOff)
        // hit api to mark them off . (can be improved)
        for(let deiveIdx = 0;deiveIdx<deviceToMarkOff.length;deiveIdx++) {
                let device = deviceToMarkOff[deiveIdx];
                let payload = {
                    'status':{
                        'isOn':false
                    }
                }
                await ServiceWorker.updateDevice(device, payload);

        }

        device.map((item) => {

            item.rebootApp = item.id
            item.restartApp = item.id
            item.info = item.id
            item.info1 = item.id
            if (item.status == null || deviceToMarkOff.includes(item.id)) {
                item.isOn = false
            } 
            else {
                item.isOn = item.status.isOn
            }
        })
        
        console.log("device:", device);

        this.setState({
            data: device.reverse(),
            showloader: false

        })
    }
    async restartApp(e, type) {
        // e.preventDefault();

        let payload = { 'deviceId': e, 'msg': type }
        console.log(payload);
        let trigger = await ServiceWorker.triggerDevice(payload)
        console.log(trigger);
    }
    async getData(deviceID, getInfo) {

        console.log("getInfo", deviceID);
        this.setState({ showModal: true, showloader1: true })
        let device = await ServiceWorker.getDevice(deviceID);
        console.log('device', device)
        let info = device.hasOwnProperty("currentContentJson") ? device.currentContentJson : "-"
        if (info != '-') {
            info = JSON.parse(info)
        }
        this.setState({
            clickedDevice: device,
            showloader1: false,
            selectedDataFull: info,
            globalVideoPercentage: device.globalVideoPercentage,
            inhouseVideoPercentage: device.inhouseVideoPercentage,
            mp4VideosPercentage: device.mp4VideosPercentage,
            thirdPartyVideoPercentage: device.thirdPartyVideoPercentage
        })
        return device
    }


    async closeModal() {
        this.setState({
            showModal: false, showModal1: false, clickedDevice: null, selectedDataFull: "-", showSetting: false,
            globalVideoPercentage: 0,
            inhouseVideoPercentage: 0,
            mp4VideosPercentage: 0,
            thirdPartyVideoPercentage: 0,
            errorValue:false,
            showloader2:false
        })
    }

    async viewPlaylist() {
        const { clickedDevice } = this.state;
        this.setState({
            showloader1: true
        })

        if (clickedDevice == null) {
            alert("no device selected")
            return
        }
        if (clickedDevice.contentRequestID == null || clickedDevice.contentRequestID == "-") {
            alert("no playlist selected")
            return
        }
        let id = clickedDevice.contentRequestID;
        let playlist = await ServiceWorker.getPlaylist(id);
        if (playlist.status) {
            var myjson = JSON.stringify(playlist.data, null, 2);
            console.log(myjson);
            var x = window.open();
            x.document.title = id;
            x.document.open();
            x.document.write('<html><body><pre>' + myjson + '</pre></body></html>');
            x.document.close();
        }

        this.setState({
            showloader1: false
        })

    }
    async trigerUserSearch(e, userID) {
        e.preventDefault();
        if (userID == '-') {
            alert("No user found!");
            return false
        }
        this.setState({
            loader3: true
        })
        let response = await ServiceWorker.loginClient(userID, false, true);
        this.setState({
            loader3: false
        })
    }

    async wait() {
        return new Promise((resolve, reject) => {
            this.interval = setInterval(() => {
                let setTime = this.state.time - 1;
                this.setState({ time: setTime })
                console.log("this.state.time", this.state.time)
                if (this.state.time <= 0) {
                    clearInterval(this.interval);

                    resolve()
                }
            }, 1000);

        })
    }

    async fetchInfoAndImage(e) {
        e.preventDefault();
        let { clickedDevice } = this.state;
        this.setState({ time: 9 })
        console.log("fetchInfoAndImage clicked")
        // remove previous log image if exist:
        if (clickedDevice.hasOwnProperty('screen') && clickedDevice.screen != null) {
            await ServiceWorker.removeFile(clickedDevice.screen.id)
        }
        //send socket req to Generate and upload new image.
        let payload = { 'deviceId': clickedDevice.id, 'msg': 'ss' }
        console.log(payload);
        let screenshotReq = await ServiceWorker.triggerDevice(payload);
        // start a small timer of 8-sec to wait for image to upload.
        await this.wait()
        let current = await this.getData(clickedDevice.id);
        console.log("here")
        if (current.hasOwnProperty('screen') && current.screen != null) {
            //await ServiceWorker.removeFile(current.screen.id)
            let url = API_URL + current.screen.url;
            window.open(url, "_blank");
        } else {
            alert("no new screenshot found");
        }


        this.setState({ time: 10 })
    }
    open(e) {
        let { clickedDevice } = this.state;
        if (clickedDevice.hasOwnProperty('screen') && clickedDevice.screen != null) {
            //await ServiceWorker.removeFile(clickedDevice.screen.id)
            let url = API_URL + clickedDevice.screen.url;
            window.open(url, "_blank");
        }
    }
    async opensettings(deviceid, name) {
        console.log(deviceid);
        await this.getData(deviceid);
        this.setState({
            showSetting: true
        })
    }

    async handelInputChange(e) {
        console.log(e.target.value, e.target.name);
        let value = e.target.value;
        let field = e.target.name;
        if(value>100 || value<0) {
           // this.setState({errorValue:true})
            return 
        }
        let { globalVideoPercentage,
            inhouseVideoPercentage,
            mp4VideosPercentage,
            thirdPartyVideoPercentage } = this.state;

        if (field == 'globalVideoPercentage') {
            this.setState({ globalVideoPercentage:value,inhouseVideoPercentage:100-value})
        }
        if (field == 'inhouseVideoPercentage') {
            this.setState({ inhouseVideoPercentage:value,globalVideoPercentage:100-value})
        }
        if (field == 'mp4VideosPercentage') {
            this.setState({ mp4VideosPercentage:value,thirdPartyVideoPercentage:100-value})
        }
        if (field == 'thirdPartyVideoPercentage') {
            this.setState({ thirdPartyVideoPercentage:value,mp4VideosPercentage:100-value})
        }
    }
    async saveVideoSettins(e){
        e.preventDefault();
        this.setState({
            showloader2:true
        })
        let { globalVideoPercentage,
            inhouseVideoPercentage,
            mp4VideosPercentage,
            thirdPartyVideoPercentage } = this.state;
        let payload= { 
            'globalVideoPercentage':globalVideoPercentage,
            'mp4VideosPercentage':mp4VideosPercentage,
            'thirdPartyVideoPercentage':thirdPartyVideoPercentage,
            'inhouseVideoPercentage':inhouseVideoPercentage
        }
        await ServiceWorker.updateDevice(this.state.clickedDevice._id,payload)
        this.setState({
            showloader2:false
        })
    }

    render() {

        const { loading, displayLength, page, loader3, clickedDevice, showloader, showloader1, selectedDataFull, showSetting } = this.state;
        let { globalVideoPercentage,
            inhouseVideoPercentage,
            mp4VideosPercentage,
            thirdPartyVideoPercentage,errorValue,showloader2 } = this.state;
        console.log("selectedDataFull", selectedDataFull)
        const CustomHeading = ({ title }) => <span style={{ color: 'black' }}>{title}</span>;
        const CustomColumn = ({ value }) => <span style={{ textAlign: 'left' }}>{value}</span>;
        const qrStatus = ({ value }) => <span style={{ textAlign: 'left' }}>{value == true ? 'ON' : 'OFF'}</span>;
        const isON = ({ value }) => <span style={{ textAlign: 'left' }}>{value == true ? <a>🟢</a> : <a>🔴</a>}</span>;
        const getInfo = ({ value }) => <a
            onClick={(e) => this.getData(value, "getInfo")} >
            <button style={{ cursor: 'pointer', float: 'inherit' }}
            >Info</button></a>

        const rebootApp = ({ value }) => <a
            onClick={(e) => this.restartApp(value, "reboot")} >
            <button style={{ cursor: 'pointer', float: 'inherit' }}
            >Reboot Device</button></a>
        const restartApp = ({ value }) => <a
            onClick={(e) => this.restartApp(value, "restart")} >
            <button style={{ cursor: 'pointer', float: 'inherit' }}
            >Restart App</button></a>
        const setting = ({ value }) => <a
            onClick={(e) => this.opensettings(value, "opensettings")} >
            <button style={{ cursor: 'pointer', float: 'inherit' }}
            >⚙️</button></a>

        return (


            <div>
                <ReactModal
                    isOpen={this.state.showModal}
                    contentLabel="Device Info"
                    style={customStyles}
                >
                    <div className="device-info">
                        <div className="controls" style={{ display: "flex", justifyContent: "space-between" }}>
                            <span className="loader-button" onClick={() => this.getData(clickedDevice.id)}>

                                {showloader1 ?
                                    <Loader />
                                    :
                                    <h3>
                                        Refresh Data:
                                        <IconContext.Provider value={{ color: "blue", size: "2em", className: "global-class-name" }}>
                                            <FiRefreshCcw />
                                        </IconContext.Provider>
                                    </h3>
                                }
                            </span>
                            <span className="get-ss">

                                <button className="common-button"
                                    onClick={(e) => this.fetchInfoAndImage(e)}
                                    disabled={this.state.time == 10 ? false : true}
                                >Fetch Screenshot</button>
                                {this.state.time > 0 && this.state.time != 10 ? `Please wait ${this.state.time} sec` : ""}
                                {/* <a style={{color:"blue"}}>View</a> <span>Last Generated: </span> */}
                                {clickedDevice != null && clickedDevice.hasOwnProperty("screen") && clickedDevice.screen != null && this.state.time == 10 ?
                                    <>
                                        {console.log("clickedDevice.screen.updatedAt", clickedDevice.screen.updatedAt)
                                        }                                    <a style={{ color: "blue" }} onClick={(e) => this.open(e)}>View</a> <span>Last Generated:{
                                            new Date(clickedDevice.screen.updatedAt).toLocaleString()
                                        } </span>
                                    </> : ""
                                }
                            </span>
                            <span className="close-button" onClick={() => this.closeModal()}>
                                <IconContext.Provider value={{ color: "red", size: "2em", className: "global-class-name" }}>
                                    <AiFillCloseCircle />
                                </IconContext.Provider>
                            </span>
                        </div>
                        {showSetting ? <table>
                            <tr>
                                <th>Parameter</th>
                                <th>value</th>
                            </tr>
                            <tr>
                                <td>ThirdParty Video %: </td>

                                <td>{clickedDevice == null ? "NA" :
                                    <input type="number"
                                        name="thirdPartyVideoPercentage"
                                        defaultValue={thirdPartyVideoPercentage}
                                        value={thirdPartyVideoPercentage}
                                        onChange={(e) => this.handelInputChange(e)}
                                    />}</td>
                            </tr>
                            <tr>
                                <td>Mp4 Video %: </td>
                                <td>{clickedDevice == null ? "NA" :
                                    <input type="number"
                                        name="mp4VideosPercentage"
                                        defaultValue={mp4VideosPercentage}
                                        value={mp4VideosPercentage}
                                        onChange={(e) => this.handelInputChange(e)}


                                    />}</td>
                            </tr>
                            <tr>
                                <td>Inhouse video %: </td>
                                <td>{clickedDevice == null ? "NA" :
                                    <input type="number"
                                        name="inhouseVideoPercentage"
                                        defaultValue={inhouseVideoPercentage}
                                        value={inhouseVideoPercentage}
                                        onChange={(e) => this.handelInputChange(e)}


                                    />}</td>
                            </tr>
                            <tr>
                                <td>Global video %: </td>
                                <td>{clickedDevice == null ? "NA" :
                                    <input type="number"
                                        name="globalVideoPercentage"
                                        defaultValue={globalVideoPercentage}
                                        value={globalVideoPercentage}
                                        onChange={(e) => this.handelInputChange(e)}

                                    />}</td>

                            </tr>{
                                showloader2?<Loader /> :<button className="common-button" disabled={errorValue}
                                onClick={(e) => this.saveVideoSettins(e)}
                            >Save</button>
                            }
                            
                        </table> :
                            <table className="data-table">
                                <tr>
                                    <th>Device Parameter</th>
                                    <th>Data</th>
                                </tr>
                                <tr>
                                    <td>Device Name:</td>
                                    <td>{clickedDevice == null ? "NA" : clickedDevice.name}</td>
                                    <td>DownloadingContent? :</td>
                                    <td>{clickedDevice == null ? "NA" : selectedDataFull == "-" ? "NA" : selectedDataFull.hasOwnProperty('isDownloading') ? selectedDataFull.isDownloading == true ? "YES" : "NO" : "NA"}</td>
                                    <td>DownloadingContent Sequence:</td>
                                    <td>{clickedDevice == null ? "NA" : selectedDataFull == "-" ? "NA" : selectedDataFull.hasOwnProperty('videoDownloading') ? selectedDataFull.videoDownloading == null ? "-" : selectedDataFull.videoDownloading : "NA"}</td>
                                    <td>Active playing set length:</td>
                                    <td>{clickedDevice == null ? "NA" : selectedDataFull == "-" ? "NA" : selectedDataFull.hasOwnProperty('activePlayingSetLen') ? selectedDataFull.activePlayingSetLen : "NA"}</td>

                                </tr>
                                <tr>
                                    <td>SequenceID:</td>
                                    <td>{clickedDevice == null ? "NA" : selectedDataFull == "-" ? "NA" : selectedDataFull.hasOwnProperty('currentVideoInfo') ? selectedDataFull.currentVideoInfo.sequence : "NA"}</td>
                                </tr>
                                <tr>
                                    <td>Current Content:</td>
                                    <td>{clickedDevice == null ? "NA" : selectedDataFull == "-" ? "NA" : selectedDataFull.hasOwnProperty('currentVideoInfo') ? selectedDataFull.currentVideoInfo.title : "NA"}</td>
                                </tr>
                                <tr>
                                    <td>Current Content ID :</td>
                                    <td>{clickedDevice == null ? "NA" : clickedDevice.CurrentContent}</td>
                                </tr>
                                <tr>
                                    <td>Playlist Generated On (Local Time)</td>
                                    <td>{clickedDevice == null ? "NA" : new Date(clickedDevice.contentLastChangedOn + 'Z').toLocaleString()}</td>
                                    <td>Playlist Generated On (UTC Time)</td>
                                    <td>{clickedDevice == null ? "NA" : clickedDevice.contentLastChangedOn}</td>
                                </tr>
                                <tr>
                                    <td>PlaylistID:</td>
                                    <td>{clickedDevice == null ? "NA" : clickedDevice.contentRequestID}</td>
                                </tr>
                            </table>
                        }
                        <button className="common-button" onClick={() => this.viewPlaylist()}>View playlist</button>
                        {loader3 ? <Loader /> :
                            <button className="common-button" onClick={(e) => this.trigerUserSearch(e, clickedDevice.businessID)}>Login Owner</button>
                        }
                    </div>
                </ReactModal>


                <div className="refresh-icon">
                    {showloader ?
                        <Loader />
                        : <span style={{ float: 'right', cursor: "pointer", marginRight: "5%" }} onClick={(e) => this.loadFull()}>
                            <IconContext.Provider value={{ color: "blue", size: "2em", className: "global-class-name" }}>
                                <FiRefreshCcw />
                            </IconContext.Provider>
                        </span>
                    }
                </div>
                <Griddle

                    data={this.state.data}
                    plugins={[plugins.LocalPlugin]}
                >

                    <RowDefinition >
                        <ColumnDefinition id="isOn" title="ON/OFF" customHeadingComponent={CustomHeading} customComponent={isON} />
                        <ColumnDefinition id="id" title="deviceId" customHeadingComponent={CustomHeading} customComponent={CustomColumn} />
                        <ColumnDefinition id="name" title="deviceName" customHeadingComponent={CustomHeading} customComponent={CustomColumn} />
                        <ColumnDefinition id="apkVersion" title="Version" customHeadingComponent={CustomHeading} />
                        <ColumnDefinition id="deviceState" title="Status" customHeadingComponent={CustomHeading} />
                        <ColumnDefinition id="rebootApp" title="Reboot Device" customHeadingComponent={CustomHeading} customComponent={rebootApp} />
                        <ColumnDefinition id="restartApp" title="Restart App" customHeadingComponent={CustomHeading} customComponent={restartApp} />
                        <ColumnDefinition id="info" title="Get Info" customHeadingComponent={CustomHeading} customComponent={getInfo} />
                        <ColumnDefinition id="info1" title="Setting" customHeadingComponent={CustomHeading} customComponent={setting} />

                    </RowDefinition>
                </Griddle>

            </div>
        )
    }
}
