import React from 'react';
import "./MerchantCampaign-style.css";
import FilterOptions from "../FilterOptions/FilterOptions";
import {ServiceWorker} from "../../services/ServiceWorker";
import AccountHistoryComponent from "../../components/AccountHistoryComponent/AccountHistoryComponent";

class MerchantCampaignHistory extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            showFilter:false,
            campaignList:[]
        };

        this.toggleFilter = this.toggleFilter.bind(this);
    }

    async componentDidMount(){
        let pubID = await localStorage.getItem('publisherID');

        let query = `publisherID=${pubID}`
        //const campaigns = await ServiceWorker.getCampaignByQueryParams(query);
        
    }

    toggleFilter(){
        this.setState({
            showFilter:!this.state.showFilter
        })
    }

    render() {
        return (
            <div className="merchant-campaign-history-main">
              
               <div className="merchant-campaign-history-contents">
                    <AccountHistoryComponent />
                    
               </div>
               
            </div>
        )
    }

}

export default MerchantCampaignHistory;
