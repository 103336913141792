import React from 'react';
import {API_URL,CMS_URL} from "../../common/config"
import thumbnailDummyImage from '../../assets/generatingthumbnail.png'
import "./CardComponent-style.css";
import { ServiceWorker } from '../../services/ServiceWorker';
import { IconContext } from "react-icons";
import { FcOk,FcCurrencyExchange,FcExpired,FcCancel,FcPackage,FcHighPriority,FcInfo } from "react-icons/fc";

function Indicator(props)
{
  const status = props.campaignStatus;
  const isText=props.isText;
  if(status==='active')
  {
    if(isText===1){return "Active"}
    else{
      return <IconContext.Provider value={{ color: "#2A2A5F",size:"2em", className: "upper-icons-three" }}>
            <FcOk />
          </IconContext.Provider>
    }
  }
  else if (status==='pendingapproval')
  {
    if(isText===1){return "In Review"}
    else{
    return <IconContext.Provider value={{ color: "#2A2A5F",size:"2em", className: "upper-icons-three" }}>
              <FcInfo />
          </IconContext.Provider> 
    }
  }
  else if(status==='onhold')
  {
    if(isText===1){return "Draft"}
    else{
    return <IconContext.Provider value={{ color: "#2A2A5F",size:"2em", className: "upper-icons-three" }}>
              <FcPackage />
          </IconContext.Provider> 
    }
  }
  else if(status==='outofmoney')
  {
    if(isText===1){return "Out Of Money"}
    else{
    return <IconContext.Provider value={{ color: "#2A2A5F",size:"2em", className: "upper-icons-three" }}>
              <FcCurrencyExchange />
          </IconContext.Provider> 
    }
  }
  else if(status==='expired')
  {
    if(isText===1){return "Expired"}
    else{
    return <IconContext.Provider value={{ color: "#2A2A5F",size:"2em", className: "upper-icons-three" }}>
              <FcExpired />
          </IconContext.Provider> 
    }
  }
  else if(status==='cancelled')
  {
    if(isText===1){return "Cancelled"}
    else{
    return <IconContext.Provider value={{ color: "#2A2A5F",size:"2em", className: "upper-icons-three" }}>
              <FcCancel />
          </IconContext.Provider> 
    }
  }
  else{
    if(isText===1){return "Deleted"}
    else{
    return <IconContext.Provider value={{ color: "#2A2A5F",size:"2em", className: "upper-icons-three" }}>
              <FcHighPriority />
          </IconContext.Provider> 
      }
  }

  
}
class CardComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          item:{},
          index: 0,
          selectValue:'',
          selectValueCopy:'',
          hideButton:true
        };


    }

    async componentDidMount(){
      console.log("card component called.-------------------------------")
      this.setState({
        item: this.props.item,
        index: this.props.index,
        campaignList:this.props.fullCampaigns,
        selectValue:this.props.item.status,
        selectValueCopy:this.props.item.status,

      })
    }

    redirectToCampaign(id){
      console.log('id revd-> ',id)
      let url= `${CMS_URL}/campaign/${id}`
      let win = window.open(url, '_blank');
      win.focus();
    }

    handelCheckedContent(idx){
      console.log("campaign index= ",idx)
      let campaignDataCopy = this.state.campaignList;
      campaignDataCopy[idx].isChecked = !campaignDataCopy[idx].isChecked
      this.setState({
        campaignList:campaignDataCopy
      });
      }

      handleChange(e,item,idx){
        console.log(e.target.value)
        console.log(item)
        this.setState({
          selectValue:e.target.value,
          itemID:item.id,
          selectedIndex:idx,
          hideButton:false
        })
      }

      async changeCampaignStatusLocal(e){
        e.preventDefault()
        let response = await ServiceWorker.changeCampaignStatus(this.state.itemID,this.state.selectValue);
        if(response.status){
          console.log(response.data)
          this.props.changeCampaignListOrder(this.state.selectedIndex,response.data)
          
          this.setState({hideButton:true},()=>{console.log("hide status",this.state.hideButton)})
          
        }
      }

    render() {
      var index = this.state.index;
      var item = this.props.item;
        return (
            <div className="campaign-card-with-dot"   >
       {/* <input type="checkbox" onClick={()=>this.handelCheckedContent(index)} style={{ position: "absolute", zIndex: 1, height: 25, width: 30 }} /> */}

       <a className='option-status'>
       <select 
        value={this.state.selectValue} 
        onChange={(e)=>this.handleChange(e,item,index)} 
       >
       <option value="active">Active</option>
        <option value="pendingApproval">Pending Approval</option>
        <option value="deleted">Delete</option>
        <option value="onHold">Hold</option>
{/* 
        <option value="draft">Draft</option>
        <option value="expired">Expired</option>
        <option value="outOfMoney">Out of money</option> */}
      </select>
       </a>

        <div className="card-image-campaign" onClick={()=> this.redirectToCampaign(item.id)}>
        { (item.contents[0].thumbnail==null|| item.contents[0].thumbnail.id.length==0) ?
          <img src={thumbnailDummyImage} alt="Avatar" />:
          <img src={API_URL+item.contents[0].thumbnail.url} alt="Avatar" />
        }
        </div>
        {/* <div style={{marginRight:10,textAlign:"end",fontWeight:"bolder"}}><Indicator campaignStatus={item.status.toLowerCase()} isText={1}/></div> */}
  
        <div className="campaign-information-block" style={{display:'flex',justifyContent: 'space-between',margin:"6px"}} >
        <div className='campaign-detail-text' >
            <strong>
            {/* {item.name.length>=28?item.name.substring(0,25)+"..":item.name} <br></br> */}
            {item.name}
            </strong>
            
          </div>
  
            <div className="campaign-details-container" onClick={()=> this.redirectToCampaign(item.id)}>

              { (item.hasOwnProperty('status')) && (item.status!=null)  ? <Indicator campaignStatus={item.status.toLowerCase()}/>:
                  <span className="dot-indicatior-notactive"></span>
              }
              <span class="tooltiptext">{ <Indicator campaignStatus={item.status.toLowerCase()} isText={1}/>}</span>
            </div>
            
          </div>
          <div className="views-shares" >
            <p style={{marginLeft:14}}>
            {item.viewCount} Views, 
              
            {" "+item.shareCount} Shares<br></br>
            </p>
           {this.state.hideButton==false? <button onClick={(e)=>this.changeCampaignStatusLocal(e)}>Save</button>
            :null}       
        </div>
          {/* Enable click portion till here */}
          
          
    </div>
        )
    }

}

export default CardComponent;