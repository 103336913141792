import axios from 'axios';
import {API_URL,limitedQuantityTitleExample,limitedTimeTitleExample,EndsOnTitle,
percentageDiscountTitleExample,NoDealTitleExample} from "../common/config";
import parsePhoneNumber from 'libphonenumber-js';

export class CommonFunctions{

    static getIdArrayFromObjectArray(inputArray)
    {
        var localArray=[];
        inputArray.forEach(elements=>{
            localArray.push(elements.id)
        });

        return localArray;

    }

    static isArrayContentsSame(inputArray1,inputArray2)
    {   
        inputArray1.sort();
        inputArray2.sort();
        if(inputArray1.length!=inputArray2.length)
        {
            return false;
        }
        for(var i=0;i<inputArray1.length;i++) 
        {
            if(inputArray1[i]!=inputArray2[i]) 
            {return false; }
        }
   
        return true;
    }

    static RemoveKeysFromArrayOfObjects(inputArray,keysToKeep)
    {
        const keys_to_keep = keysToKeep;
            const newData = array => array.map(o => keys_to_keep.reduce((acc, curr) => {
                acc[curr] = o[curr];
                return acc;
              }, {}));

            return newData(inputArray);
    }

    static camelCaseToNormal(str) {
        var out = str.replace(/^\s*/, "");  // strip leading spaces
        out = out.replace(/^[a-z]|[^\s][A-Z]/g, function(str, offset) {
            if (offset == 0) {
                return(str.toUpperCase());
            } else {
                return(str.substr(0,1) + " " + str.substr(1).toUpperCase());
            }
        });
        return(out);
    }

    static renameKey(obj, old_key, new_key) {    
        // check if old key = new key   
            if (old_key !== new_key) {                   
               Object.defineProperty(obj, new_key, // modify old key 
                                    // fetch description from object 
               Object.getOwnPropertyDescriptor(obj, old_key)); 
               delete obj[old_key];                // delete old key 
               } 
        } 

    
    static async campaignIDtoName(data)
    {
        let campaignReceived = data;
        const publisher = await localStorage.getItem('publisherID');
        let query = "publisherID="+publisher
        const campaignRequest= await axios.get(`${API_URL}/campaigns?${query}`);
        let campaignResponse= await campaignRequest.data;
        let allData = this.RemoveKeysFromArrayOfObjects(campaignResponse,['id','name'])
        console.log("allData",allData);

        campaignReceived.forEach(elem=>{
            allData.forEach(elem2=>{
                if(elem.campaignID==elem2.id)
                {
                    elem.campaignID=elem2.name;
                }
            })
        })
        return campaignReceived;
    }

    static truncateString(str, num) {
        // use num=30
        // If the length of str is less than or equal to num
        // just return str--don't truncate it.
        if (str.length <= num) {
          return str
        }
        // Return str truncated with '...' concatenated to the end of str.
        return str.slice(0, num) + '...'
      }

    static removeDuplicateCampaignObjects(inputArray){
        let campaigns=inputArray;
        var idTrack =[];
            var campaignsToConsider=[];
            campaigns.forEach(obj=>{
                //console.log("myobj=",obj)
                if(!idTrack.includes(obj.campaignid))
                {   
                    campaignsToConsider.push(obj);
                    idTrack.push(obj.campaignid)
                }
            })
            let finalCampaign=[]
            //Extra check to remove any corrupted data.
            campaignsToConsider.forEach(campaign=>{
                if(campaign.campaignID!=undefined && campaign.campaignid!=undefined)
                {  
                    finalCampaign.push(campaign)
                }
            })
            console.log("myArrnoDup=",finalCampaign)
            return finalCampaign;
    }

    static async areDatesSame(d1,d2){
        let dd1 = String(d1.getDate()).padStart(2, '0');
        let mm1 = String(d1.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy1 = d1.getFullYear();

        let first = mm1 + '/' + dd1 + '/' + yyyy1;

        let dd = String(d2.getDate()).padStart(2, '0');
        let mm = String(d2.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = d2.getFullYear();

        let sec = mm + '/' + dd + '/' + yyyy;

        if(first==sec){
            return true;
        }else{
            return false;
        }
    }

    static async getTitleExample(type){
        console.log('getTitleExample called ',type.toLowerCase())
        switch(type.toLowerCase()){
                
            case 'limitedtime':
                return limitedTimeTitleExample;
                break;
            
            case 'limitedqty':
                return limitedQuantityTitleExample;
                break;
            
            case 'percentagediscount':
                return percentageDiscountTitleExample;
                break;
            
            case 'nodeal':
                return NoDealTitleExample;
                break;

            case 'endson':
                return EndsOnTitle;
                break;

            default:
                return '';
                break;

        }
    }

    static async removeArrayDuplicates(arr){
        let s = new Set(arr);
        let it = s.values();
        return Array.from(it)
    }
      
    static async isValidPhoneNumber(number){
        try{
        var phoneNumber = parsePhoneNumber(number);
        console.log("phoneNumber ---",phoneNumber)
        console.log("is valid->",phoneNumber.isValid())
        if(phoneNumber.isValid() === true && number!='')
        {
          return true;
        }
        return false;
        }catch(e){
            return false;
        }
        
    }

    static async isEmailValid(email){
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(email)) {
          return false;
        }return true;
    }

    static removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject  = {};
   
        for(var i in originalArray) {
           lookupObject[originalArray[i][prop]] = originalArray[i];
        }
   
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
         return newArray;
    }

}
