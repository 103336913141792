import React from "react";
import { Link, Route, Switch,BrowserRouter } from "react-router-dom";
import MerchantPage from "../MerchantPage/MerchantPage";
import UserPage from "../UserPage/UserPage";
import Login from "../Login/Login"; 
import DashboardPage from "../Dashboard/DashboardPage";
import PayoutTableFullViewComponent from "../../components/DashboardComponents/PayoutTableFullViewComponent";
import SubscriberPage from "../Subscriber/SubscriberPage";

import {ServiceWorker} from "../../services/ServiceWorker";
const Root = () => {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route component={PayoutTableFullViewComponent} path={"/payoutview/:id"}/>
            <Route component={DashboardPage} path="/dashboard"/>
            <Route component={SubscriberPage} path="/display"/>
            <Route component={UserPage} path="/user" />
            <Route component={Login} path="/login" />
            <Route component={MerchantPage} path="/" />

            
          </Switch>
        </BrowserRouter>
      </div>
    );
  };
  
  export default Root;
  
  