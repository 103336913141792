import React from 'react'
import { FaClosedCaptioning } from 'react-icons/fa';
import {primaryColor, whiteColor,RECAPTCHA_KEY,commonButtonColor} from "../../common/config";
import { ServiceWorker } from "../../services/ServiceWorker";
import Griddle, { plugins, RowDefinition, ColumnDefinition} from 'griddle-react';
import {CommonFunctions} from '../../common/CommonFunctions';
import { FaFileDownload,FaWindowClose } from "react-icons/fa";
import { IconContext } from "react-icons";
import ReactModal from 'react-modal';
import "./AccountHistory-style.css";
import 'rsuite-table/dist/css/rsuite-table.css';
import { Table } from 'rsuite';
import csvDownload from 'json-to-csv-export'
import moment from 'moment';
moment().format();
const { Column, HeaderCell, Cell, Pagination } = Table;

const customStyles = {
    content : {
      top                   : '45%',
      left                  : '58%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      height                 : 'max-content',
      width                 :'65%'
    }
  };

class PayoutHistory extends React.Component{
    constructor(props)
    {   super(props);
        this.state={
            currentlySelected:'campaign',
            displayLength: 10,
            loading: false,
            page: 1,
            data:[],
            dataChild:[],
            showModal:false,
            selectedIndex:null,
            dataToSet:null
        }

        this.setIndex = this.setIndex.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.downloadData = this.downloadData.bind(this);

      
    }

    async componentDidMount()
    {
        console.log("component called.");
        //const keys_to_keep = ['cost', 'userID','campaignID','updatedAt'];
        let payoutHistoryResponse = await ServiceWorker.getPayoutHistory()
        payoutHistoryResponse= CommonFunctions.RemoveKeysFromArrayOfObjects(payoutHistoryResponse,['date','updatedAt','amount','items']);
        let dataChildLocal=[];
        let init=0;
        payoutHistoryResponse.forEach(obj=>{
            obj['itemCount']=obj.items.length;
            dataChildLocal.push({"init":init,'data':obj.items});
            init=init+1;
        })
        payoutHistoryResponse.forEach(obj => CommonFunctions.renameKey(obj, 'items', 'children'));
        console.log("payoutHistoryResponse=",payoutHistoryResponse)
        await this.setState({
            data:payoutHistoryResponse,
            dataChild:dataChildLocal
        })
        console.log("table data=",this.state.data)
        console.log("child=",this.state.dataChild)
    }

    async setIndex(e,idx)
    {   e.preventDefault();
        console.log("testclick",idx);
        let dataChild = this.state.dataChild;
        let dataToSet=null
        dataChild.forEach(elem=>{
            if(elem.init==idx){
                dataToSet=elem.data
            }
        })
        dataToSet= CommonFunctions.RemoveKeysFromArrayOfObjects(dataToSet,['campaignID','status','cost','redeemID']);
        dataToSet = await CommonFunctions.campaignIDtoName(dataToSet);
        await this.setState({
                selectedIndex:idx,
                showModal:true,
                dataToSet:dataToSet
            })
            console.log("Table=",this.state.dataChild)
    }

    async closeModal()
    {   console.log("close called")
        await this.setState({
            showModal:false
        })
    }
    async downloadData(){
        console.log("Download Called.");
        let today = moment(new Date()).format('MM-DD-YYYY')
        let myData = this.state.data;
       // myData.forEach(obj => CommonFunctions.renameKey(obj, 'name', 'campaignName'));
        myData= CommonFunctions.RemoveKeysFromArrayOfObjects(myData,['date','amount','itemCount']);
        
        csvDownload(myData,today+'_payoutHistory.csv')
    }

    render(){
        const { loading, displayLength, page,data } = this.state;
        const CustomHeading = ({title}) => <span style={{ color: 'black' }}>{title}</span>;


        return (
            <div className='purchase-history-body' style={{display:"flex"}}>
            <div className='purchase-history-table' style={{width:"100%"}}>
              
              <Griddle
                  data={data}
                  plugins={[plugins.LocalPlugin]}
                  components={{
                    RowEnhancer: OriginalComponent =>
                      props => (
                        <OriginalComponent
                          {...props}
                        //  onClick={() => console.log(`Click Row ${props.griddleKey}`)}
                        onClick={(e)=>this.setIndex(e,props.griddleKey)}
                          />
                      ),
                  }}
              >
                  <RowDefinition >
                      <ColumnDefinition id="date" title="Date" customHeadingComponent={CustomHeading} />
                      {/* <ColumnDefinition id="updatedAt" title="Updated at" customHeadingComponent={CustomHeading}/> */}
                      <ColumnDefinition id="amount" title="Amount $" customHeadingComponent={CustomHeading} />
                      <ColumnDefinition id="itemCount" title="Campaign count" customHeadingComponent={CustomHeading}/>
                  </RowDefinition >
              </Griddle>
              </div>
              {this.state.data.length>0?<div className='campaign-history-download' style={{height:"fit-content",width:"auto"}} onClick={this.downloadData}>
          <IconContext.Provider value={{ color:commonButtonColor,size:"2em", className: "upper-icons-three" }}>
                  <FaFileDownload />
              </IconContext.Provider>
          </div>:""}

          <ReactModal 
            isOpen={this.state.showModal}
            contentLabel="Items"
            style={customStyles}
            >
                 <div className='purchase-history-body' style={{display:"flex"}}>
                  <div className='purchase-history-table' style={{width:"100%"}}>
                    
                    <Griddle
                        data={this.state.dataToSet}
                        plugins={[plugins.LocalPlugin]}
                    >
                  <RowDefinition >
                      <ColumnDefinition id="campaignID" title="Campaign Name" customHeadingComponent={CustomHeading} />
                      <ColumnDefinition id="status" title="Status" customHeadingComponent={CustomHeading}/>
                      <ColumnDefinition id="cost" title="Cost" customHeadingComponent={CustomHeading} />
                      <ColumnDefinition id="redeemID" title="Redeem Reference" customHeadingComponent={CustomHeading}/>
                  </RowDefinition >
                        
                    </Griddle>
                    </div>
                    {this.state.data.length>0?<div className='campaign-history-download' style={{height:"fit-content",width:"auto"}} onClick={this.downloadData}>
                {/* <IconContext.Provider value={{ color: "#2A2A5F",size:"2em", className: "upper-icons-three" }}>
                        <FaFileDownload />
                    </IconContext.Provider> */}
                </div>:""}
                <a  onClick={this.closeModal} style={{cursor:"pointer"}}>
                <IconContext.Provider value={{ color: "red",size:"2em", className: "upper-icons-three" }}>
                        <FaWindowClose />
                    </IconContext.Provider>
                </a>
            </div>
            </ReactModal>
      </div>
        )

    }

}
export default PayoutHistory;
