import React from 'react';
import Griddle, { plugins, RowDefinition, ColumnDefinition } from 'griddle-react';
import { ServiceWorker } from "../../services/ServiceWorker";
import { Table } from 'rsuite';
import CampaignDropDownComponent from "../CampaignDropDownComponent/CampaignDropDownComponent";
import {CMS_URL} from '../../common/config'
import moment from 'moment';

class CampaignStatusTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            showDropdown: false,
            campaignOpened:false
        }
      
    }

    async componentDidMount() {
        this.renderData();
    }

    renderData() {
        let data = this.props.campaignData;
        console.log(data)
        try {
            data.map(elem => {
                elem['open'] = elem.id;
                elem['updatedAt'] = String(moment(elem.updatedAt).format('MM/DD/YYYY'));
                console.log("elem['updatedAt'] ",elem['updatedAt'])
                elem['createdAt'] = String(moment(elem.createdAt).format('MM/DD/YYYY'));
            })
        } catch (e) {
            console.log(e)
        }
        let showCustomOption = this.props.showCustomOption;
        console.log('showCustomOption ', showCustomOption)
        this.setState({
            data: data,
            showCustomOption: showCustomOption
        });
    }


    componentDidUpdate(prevProps,prepState) {
        // Typical usage (don't forget to compare props):
        if ((this.props.campaignData !== prevProps.campaignData)) {
            this.renderData();
        }
    }


    async activate(e) {
        console.log("cam = ", e);
        await ServiceWorker.changeCampaignStatus(e, 'active')
        let localCampaigns = this.state.data;
        let localCampaignsData = localCampaigns.filter(function (elem) {
            return elem.id != e;
        })
        await this.setState({ data: localCampaignsData });

    }

    changeCampaignListOrder(idx, obj) {
        let campaign = this.state.data;
        campaign[idx] = obj;
        this.setState({ data: campaign })
        return true;
    }

    async viewCampaign(campaign){
        var id = campaign
        console.log(campaign)
        let data = this.state.data;
        let publisherSection = data.filter(function(elem){
            return elem.id==campaign
        })
        publisherSection=publisherSection[0].publisherID
        console.log('publisherSection=',publisherSection)
        let response = await ServiceWorker.loginClient(publisherSection,true);
        try{
            console.log('response',response[0].data.publisherID);
            
            if(response!=false){
                await localStorage.setItem("clientEmail",publisherSection);
            }
            let url= `${CMS_URL}/campaign/${id}`
            console.log("opening ",url)
            var win = window.open(url, '_blank');
            
            if(win==null){
                alert('Cannot open campaign, Pop-up is being blocked by browser, kindly enable it for this url.')
            }else{
                win.focus();
            }

        }catch(e){
            console.log(e);
        }
        await this.setState({
            campaignOpened:true,
        })

    }





    render() {
        const { loading, displayLength, page, } = this.state;
        const CustomHeading = ({ title }) => <span style={{ color: 'black' }}>{title}</span>;
        const CustomColumn = ({ value }) => <span style={{ textAlign: 'left' }}>{value}</span>;
        // const customActivate = ({value}) => <a 
        // onClick={(e)=>this.activate(value)} >
        // <button style={{cursor:'pointer' }}>Activate</button></a>
        const sendMail = ({ value }) => <a
            onClick={(e) => this.activate(value)} >
            <button style={{ cursor: 'pointer' }}>Send alert</button></a>

        console.log('render sectoin campaignOpened',this.state.campaignOpened)
        const customActivate = ({ value }) => 
        <CampaignDropDownComponent
            key={value}
            from={'table'}
            campaign={value}
            status={this.state.data.filter(function (id) {
                return id.id == value;
            })}
            changeCampaignListOrder={(idx, obj) => this.changeCampaignListOrder(idx, obj)}
        />
        const customActivateDisabled = ({ value }) => <a
        key={value}
        onClick={(e) => alert('please review campaign using view first.')} >
        <button style={{ cursor: 'pointer' }}>Disabled</button></a>
    

        const visitCampaign = ({ value }) => <a
            onClick={(e) => this.viewCampaign(value)} >
            <button style={{ cursor: 'pointer' }}>view</button></a>


        return (
            <div className='purchase-history-body' style={{ display: "flex", background: "white" }}>
                <div className='purchase-history-table' style={{ width: "100%" }}>
                    <h3>{this.props.tableToShowName}</h3>
                    <Griddle
                        data={this.state.data}
                        plugins={[plugins.LocalPlugin]}
                    >
                        <RowDefinition >
                            <ColumnDefinition id="name" title="Campaign Name" customHeadingComponent={CustomHeading} customComponent={CustomColumn} />
                            {console.log("this.state.campaignOpened-> ",this.state.campaignOpened)}                           
                            <ColumnDefinition id="open" title="Open" customHeadingComponent={CustomHeading} customComponent={visitCampaign}/> 
                            <ColumnDefinition id="publisherID" title="Merchant ID" customHeadingComponent={CustomHeading} />
                            <ColumnDefinition id="createdAt" title="Created At (MM/DD/YYYY)" customHeadingComponent={CustomHeading} />
                            <ColumnDefinition id="updatedAt" title="Updated At (MM/DD/YYYY)" customHeadingComponent={CustomHeading} />

                            <ColumnDefinition id="status" title="status" customHeadingComponent={CustomHeading} />
                            {/* <ColumnDefinition id="id" title="Action" customHeadingComponent={CustomHeading} customComponent={this.state.campaignOpened===true?customActivate:customActivateDisabled} /> */}
                            
                        </RowDefinition>
                    </Griddle>
                </div>
            </div>
        )
    }


}
export default CampaignStatusTable;